.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.tableAddress {
  &Header {
    position: relative;
  }
  &Description {
    position: absolute;
    color: #717780;
    font-size: 14px;
    font-weight: 400;
    line-height: 114.286%;
  }
}
.reportTable :global(table) {
  border-spacing: 0;
  min-width: 100%;
}
.reportTable :global(.ant-table-cell) {
  padding: 10px 12px;
}
.reportTable :global(.ant-table-expanded-row-fixed) {
  background: rgba(154, 169, 187, 0.06);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #5b6878;
  padding: 10px 0;
}
.reportTable :global(.ant-table-placeholder .ant-table-cell) {
  padding: 10px 0;
  background: rgba(154, 169, 187, 0.06);
  border-radius: 8px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #5b6878;
}
.reportTable :global(.ant-table-container) {
  margin-bottom: 30px;
}
.reportTable :global(.ant-checkbox-checked) {
  border: 1px solid #5b6878;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
.reportTable :global(.ant-checkbox-checked .ant-checkbox-inner), :global(.ant-checkbox-checked .ant-checkbox-inner):hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.reportTable :global(.ant-checkbox-inner), :global(.ant-checkbox-inner):hover {
  background-color: transparent !important;
  border-color: black !important;
}
.reportTable :global(.ant-checkbox-checked .ant-checkbox-inner:after) {
  border-color: black;
}
.reportTable :global(th .ant-checkbox-inner:after) {
  border-color: black;
  background-color: black;
}
.reportTable :global(th .ant-checkbox-checked .ant-checkbox-inner:after) {
  background-color: transparent;
}
.reportTable :global(.ant-checkbox-indeterminate:hover .ant-checkbox-inner) {
  border-color: black !important;
}
.reportTable :global(.ant-checkbox-wrapper-checked) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportTable :global(.ant-table-thead > tr > th) {
  color: #2d3035;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  padding: 16px 12px;
  margin: 0 10px;
}
.reportTable :global(.ant-table-tbody > tr > td) {
  padding: 10px 12px;
}
.reportTable :global(.ant-table-thead > tr > th),
.reportTable :global(.ant-table-tbody > tr > td)
{
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.reportTable :global(.ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover),
.reportTable :global(.ant-table-tbody > .ant-table-row-selected > .ant-table-cell),
.reportTable :global(.ant-table-tbody > .ant-table-row-selected > .ant-table-cell-row-hover)
{
  background-color: transparent !important;
}
.reportTable :global(.ant-table-thead tr th)::before {
  display: none;
}
.cell {
  color: #2d3035;
  border-radius: 8px;
  cursor: pointer;
}
.reportTable :global(.ant-table-thead > tr > .ant-table-selection-column) {
  padding: 10px 14px;
  border-radius: 8px;
  width: 25px;
}
.reportTable :global(.ant-checkbox) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reportTable
  :global(
    .ant-table-tbody
      > tr
      > .ant-table-selection-column
      > .ant-checkbox-wrapper
      > .ant-checkbox
      > .ant-checkbox-input
  ) {
  cursor: pointer;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  padding: 14px;
  margin: 0;
  color: #5b6878;
  &:checked {
    accent-color: #ffffff;
  }
}
.reportTable
  :global(
    .ant-table-thead
      > tr
      > .ant-table-selection-column
      > .ant-table-selection
      > .ant-checkbox-wrapper
      > .ant-checkbox
      > .ant-checkbox-input
  ) {
  cursor: pointer;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  padding: 14px;
  margin: 0;
  color: #5b6878;
  &:checked {
    accent-color: #ffffff;
  }
}
.reportTable :global(.ant-input-affix-wrapper) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border: 1px solid #b3bcc9;
  box-shadow: none;
  border-radius: 4px;
}
.reportTable :global(.ant-input-affix-wrapper > .ant-input) {
  width: 90%;
  border: none;
  color: #2d3035;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  padding: 10px 14px 9px;
  background: transparent;
}
.reportTable :global(.ant-input-suffix) {
  padding-right: 12px;
}
.reportTable :global(.ant-input-suffix > svg) {
  width: 20px;
  height: 20px;
}
.reportTable :global(.ant-pagination-options),
.reportTable :global(.ant-pagination-item-ellipsis) {
  display: none;
}
.reportTable :global(.ant-pagination-jump-next),
.reportTable :global(.ant-pagination-jump-prev) {
  color: #1b74b3;
  cursor: pointer;
}
.reportTable :global(.ant-pagination-prev) {
  margin-right: 5px;
}
.reportTable :global(.ant-pagination) {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin-left: 10px;
}
.reportTable :global(.ant-pagination > .ant-pagination-next > button),
.reportTable :global(.ant-pagination > .ant-pagination-prev > button) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #04062c;
  background-color: #fff;
  font-size: 14px;
  line-height: 17px;
  border: 2px solid #04062c50;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #1b74b3;
    opacity: 0.8;
    color: #fff;
  }
  & > span {
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
  }
}
.reportTable :global(.ant-pagination > .ant-pagination-disabled > button) {
  cursor: not-allowed;
  & > span {
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    color: #04062c40;
  }
}
.reportTable :global(.ant-pagination > .ant-pagination-item) {
  display: flex;
  align-items: center;
  color: #04062c;
  font-family: 'Roboto';
  background-color: #fff;
  font-size: 14px;
  line-height: 17px;
  border: 2px solid #04062c50;
  padding: 4px 10px;
  font-weight: 400;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    color: #1b74b3;
    border: 2px solid #1b74b3;
  }
}
.reportTable :global(.ant-pagination > .ant-pagination-item-active) {
  color: #5796d2;
  background-color: #fff;
  opacity: 0.8;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Roboto';
  border: 2px solid #5796d2;
  padding: 4px 10px;
  &:hover {
    border: 2px solid #0760a0;
  }
}
.reportTable :global(.ant-pagination > .ant-pagination-item > a) {
  padding: 0;
}
.reportTable :global(.ant-pagination > .ant-pagination-item-active > a) {
  color: #5796d2;
}
input:focus-visible {
  outline: none;
}

.addAnotherButton {
  display: flex;
  align-items: center;
  align-self: flex-start;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.addAnotherAddressText {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #196faf;
  margin-left: 8px;
}
.inputCell {
  background: transparent;
  width: 460px;
  ::placeholder {
    color: #9aa9bb !important;
  }
}
.reportTable :global(.ant-pagination) {
  margin-bottom: 0;
}
@media screen and (max-width: 1020px) {
  .modalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalDescription {
    margin: 14px 0 30px;
    font-size: 14px;
    line-height: 20px;
  }
  .modalButtonContainer {
    margin: 0 15px 10px 15px;
  }
  .closeModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
    margin-right: 0px;
  }
  .confirmModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .addAnotherButton {
    align-self: flex-start;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 20px;
  }
  .addAnotherAddressText {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
  }
  .inputCell {
    background: transparent;
    width: 400px;
    ::placeholder {
      color: #9aa9bb !important;
    }
  }
  .reportTable :global(.ant-input-affix-wrapper) {
    width: 400px;
  }
  .reportTable :global(.ant-table-thead > tr > th) {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 8px;
    border-radius: 8px;
    margin: 0 6px;
  }
  .reportTable :global(.ant-table-tbody > tr > td) {
    padding: 10px 8px;
  }
  .reportTable :global(.ant-input-affix-wrapper > .ant-input) {
    font-size: 14px;
    line-height: 17px;
  }
  .reportTable :global(.ant-input-suffix) {
    padding-right: 10px;
  }
  .reportTable :global(.ant-input-suffix > svg) {
    width: 18px;
    height: 18px;
  }
  .reportTable :global(.ant-table-thead > tr > .ant-table-selection-column) {
    padding: 6px 10px;
  }
  .reportTable
    :global(
      .ant-table-tbody
        > tr
        > .ant-table-selection-column
        > .ant-checkbox-wrapper
        > .ant-checkbox
        > .ant-checkbox-input
    ) {
    width: 14px;
    height: 14px;
    padding: 10px;
  }
  .reportTable
    :global(
      .ant-table-thead
        > tr
        > .ant-table-selection-column
        > .ant-table-selection
        > .ant-checkbox-wrapper
        > .ant-checkbox
        > .ant-checkbox-input
    ) {
    width: 14px;
    height: 14px;
    padding: 10px;
  }
  .reportTable :global(.ant-pagination) {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin-left: 0;
    margin-top: 30px;
  }
}

@media screen and (max-width: 840px) {
  .tableAddress {
    &Description {
      font-size: 11px;
      white-space: nowrap;
    }
  }
  .closeModalButton {
    width: 150px;
  }
  .confirmModalButton {
    width: 150px;
  }
  .inputCell {
    width: 250px;
  }
  .reportTable :global(.ant-input-affix-wrapper) {
    width: 250px;
  }
}

@media screen and (max-width: 564px) {
  .inputCell {
    width: 140px;
  }
  .reportTable :global(.ant-input-affix-wrapper) {
    width: 140px;
  }
  .reportTable :global(.ant-table-tbody > tr > td) {
    padding: 10px 2px;
  }
}

@media screen and (max-width: 420px) {
  .modalTitle {
    font-size: 20px;
    line-height: 28px;
  }
  .modalDescription {
    margin: 14px 0 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .modalButtonContainer {
    margin: 0 5px 5px 5px;
  }
  .closeModalButton {
    width: 130px;
    font-size: 12px;
    line-height: 15px;
  }
  .confirmModalButton {
    width: 130px;
    font-size: 12px;
    line-height: 15px;
  }
  .addAnotherButton {
    align-self: flex-start;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .addAnotherAddressText {
    font-size: 11px;
    line-height: 14px;
    margin-left: 6px;
  }
  .reportTable :global(.ant-table-thead > tr > th) {
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    padding: 8px 6px;
    margin: 0 4px;
  }
  .reportTable :global(.ant-input-affix-wrapper > .ant-input) {
    font-size: 11px;
    line-height: 15px;
  }
  .reportTable :global(.ant-input-suffix) {
    padding-right: 6px;
  }
  .reportTable :global(.ant-input-suffix > svg) {
    width: 15px;
    height: 15px;
  }
  .reportTable :global(.ant-table-thead > tr > .ant-table-selection-column) {
    padding: 8px 6px;
  }
  .reportTable
    :global(
      .ant-table-tbody
        > tr
        > .ant-table-selection-column
        > .ant-checkbox-wrapper
        > .ant-checkbox
        > .ant-checkbox-input
    ) {
    width: 12px;
    height: 12px;
    padding: 8px;
  }
  .reportTable
    :global(
      .ant-table-thead
        > tr
        > .ant-table-selection-column
        > .ant-table-selection
        > .ant-checkbox-wrapper
        > .ant-checkbox
        > .ant-checkbox-input
    ) {
    width: 12px;
    height: 12px;
    padding: 8px;
  }
  .clearSelectionText {
    font-size: 12px;
    line-height: 20px;
  }
  .inputCell {
    width: 100px;
  }
  .reportTable :global(.ant-input-affix-wrapper) {
    width: 100px;
  }
  .reportTable :global(.ant-table-tbody > tr > td) {
    padding: 10px 2px;
  }
  .reportTable :global(.ant-pagination) {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 0;
    margin-top: 40px;
  }
  .reportTable :global(.ant-pagination > .ant-pagination-next > button),
  .reportTable :global(.ant-pagination > .ant-pagination-prev > button) {
    color: #04062c;
    background-color: #fff;
    font-size: 11px;
    line-height: 14px;
    border: 2px solid #04062c50;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #1b74b3;
      opacity: 0.8;
      color: #fff;
    }
    & > span {
      font-size: 8px;
      line-height: 8px;
      font-weight: 500;
    }
  }
  .reportTable :global(.ant-pagination > .ant-pagination-disabled > button) {
    cursor: not-allowed;
    & > span {
      font-size: 8px;
      line-height: 8px;
      font-weight: 500;
      color: #04062c40;
    }
  }
  .reportTable :global(.ant-pagination > .ant-pagination-item) {
    color: #04062c;
    background-color: #fff;
    font-size: 11px;
    line-height: 14px;
    border: 2px solid #04062c50;
    padding: 4px 10px;
    font-weight: 500;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      color: #1b74b3;
      border: 2px solid #1b74b3;
    }
  }
  .reportTable :global(.ant-pagination > .ant-pagination-item-active) {
    color: #1b74b3;
    background-color: #fff;
    opacity: 0.8;
    font-size: 11px;
    line-height: 14px;
    border: 2px solid #1b74b3;
    padding: 4px 10px;
    &:hover {
      background-color: #fff;
      color: #04062c;
    }
  }
}
