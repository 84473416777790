.title {
  &Container {
    display: flex;
  }
  &Text {
    all: unset;
    margin-left: 18px;
    margin-right: 20px;
    flex: 1;
    width: 100%;
    background-color: transparent !important;
    color: #2d3035 !important;
    border: none !important;
    box-shadow: none !important;
    &:disabled {
      cursor: default;
    }
  }
  &IconSubmit {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
      fill: rgb(13, 154, 13);
    }
  }
  &IconClose {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
    svg {
      width: 22px;
      height: 22px;
      fill: rgb(219, 63, 63);
    }
  }
  &Cell {
    margin-left: 10px;
  }
}

.completeTable :global(.ant-table-content > table) {
  width: 100%;
}
.completeTable :global(.ant-table-cell) {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  padding: 16px 12px;
}
.completeTable :global(.ant-table-column-title) {
  margin-right: 14px;
}
.completeTable :global(.ant-table-thead > tr) {
  background: #ffffff;
}
.completeTable :global(.ant-table-thead > tr > .ant-table-column-has-sorters):hover {
  background: #ffffff;
}
.completeTable :global(.ant-table-tbody tr td), :global(.ant-table-thead tr th) {
  background: inherit;
}
.completeTable :global(.ant-table-thead > tr > td) {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
}
.completeTable :global(.ant-table-thead > tr > .ant-table-column-has-sorters) {
  cursor: pointer;
}
.completeTable :global(.ant-table-tbody > tr > td) {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
  border: none;
}
.completeTable :global(.ant-table-thead > tr > th) {
  background: #ffffff;
  border: none;
}
.completeTable :global(.ant-table-thead > tr > .ant-table-column-has-sorters) {
  background: #ffffff;
}
.completeTable :global(table) {
  border-collapse: initial;
  border-spacing: 0;
}
.completeTable :global(.ant-table-tbody tr) {
  background: white;
  border-radius: 8px !important;
}
.completeTable :global(.ant-table-tbody tr:nth-child(even)) {
  background: #f8fafd;
}
.completeTable :global(.ant-table-column-sorter) {
  display: flex;
  align-items: center;
}
.completeTable :global(.ant-table-thead tr th)::before {
  display: none;
}
.completeTable :global(.ant-table-placeholder .ant-table-cell) {
  border: none;
}
.completeTable :global(.ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover),
{
  background-color: inherit;
}
.completeTable :global(.ant-table-column-sorters) {
  display: block !important;
}
.completeTable :global(.ant-table-column-sorter) {
  display: inline-block;
  max-height: 16px;
}

.completeTable :global(.ant-pagination-options),
.completeTable :global(.ant-pagination-item-ellipsis) {
  display: none;
}
.completeTable :global(.ant-pagination-jump-next),
.completeTable :global(.ant-pagination-jump-prev) {
  color: #1b74b3;
  cursor: pointer;
}
.completeTable :global(.ant-pagination-prev) {
  margin-right: 5px;
}
.completeTable :global(.ant-pagination) {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin-left: 10px;
  margin-top: 20px;
}
.completeTable :global(.ant-pagination > .ant-pagination-next > button),
.completeTable :global(.ant-pagination > .ant-pagination-prev > button) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #04062c;
  background-color: #fff;
  font-size: 14px;
  line-height: 17px;
  border: 2px solid #04062c50;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #1b74b3;
    opacity: 0.8;
    color: #fff;
  }
  & > span {
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
  }
}
.completeTable :global(.ant-pagination > .ant-pagination-disabled > button) {
  cursor: not-allowed;
  & > span {
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
    color: #04062c40;
  }
}
.completeTable :global(.ant-pagination > .ant-pagination-item) {
  display: flex;
  align-items: center;
  color: #04062c;
  font-family: 'Roboto';
  background-color: #fff;
  font-size: 14px;
  line-height: 17px;
  border: 2px solid #04062c50;
  padding: 4px 10px;
  font-weight: 400;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    color: #1b74b3;
    border: 2px solid #1b74b3;
  }
}
.completeTable :global(.ant-pagination > .ant-pagination-item-active) {
  color: #5796d2;
  background-color: #fff;
  opacity: 0.8;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Roboto';
  border: 2px solid #5796d2;
  padding: 4px 10px;
  &:hover {
    border: 2px solid #0760a0;
  }
}
.completeTable :global(.ant-pagination > .ant-pagination-item > a) {
  padding: 0;
}
.completeTable :global(.ant-pagination > .ant-pagination-item-active > a) {
  color: #5796d2;
}
.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .completeTable :global(.ant-table-thead th) {
    background: #f8fafd;
  }
  .completeTable :global(.ant-table-tbody tr:nth-child(even)) {
    background: #ffffff;
  }
  .completeTable :global(.ant-table-tbody tr:nth-child(odd)) {
    background: #f8fafd;
  }
  .completeTable :global(.ant-table-thead > tr > th) {
    font-size: 12px;
    line-height: 25px;
    padding: 8px 10px;
    margin: 0 6px;
  }
  .completeTable :global(.ant-table-tbody > tr > td) {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 10px;
  }
}
