.container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.headerButton {
  align-self: start;
  padding: 14px 42px;
  background: #ef6f22;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  text-align: center;
  color: #ffffff;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f28c4e;
  }
}
.content {
  margin: 126px auto 65px;
  padding: 44px 36px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.statusTitle {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  color: #04062c;
}

@media screen and (max-width: 1020px) {
  .content {
    margin: 126px auto 50px;
    padding: 1.5rem 1.3rem;
  }
  .headerContainer {
    margin-bottom: 10px;
  }
  .statusTitle {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
  }
  .headerContainer {
    align-items: center;
  }
  .headerButton {
    align-self: auto;
    font-weight: 400;
    font-size: 14px;
    margin-top: 20px;
  }
  .content {
    margin: 126px auto 30px;
    width: 85%;
    padding: 1rem 0.8rem;
  }
  .statusTitle {
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 420px) {
  .content {
    margin: 126px auto 30px;
    width: 85%;
    padding: 1rem 0.8rem;
  }
  .statusTitle {
    font-size: 22px;
    line-height: 28px;
  }
}
