.container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.grayContainer {
  background: #f8fafd;
}
.contentHeader {
  margin-top: 126px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  &Container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &Title {
    font-weight: 700;
    font-size: 32px;
    line-height: 175%;
    color: #2d3035;
  }
  &SubTitle {
    font-weight: 400;
    font-size: 22px;
    color: #2d3035;
  }
}
.content {
  margin: 30px auto 65px;
  padding: 44px 36px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &Title {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    color: #2d3035;
  }
  &Button {
    align-self: start;
    padding: 14px 42px;
    background: #ef6f22;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 156%;
    text-align: center;
    color: #ffffff;
    transition: background-color 0.3s;
    &:hover {
      background-color: #f28c4e;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    &Container {
      padding: 0 20px;
    }
    &Header {
      margin-top: 110px;
      justify-content: center;
      align-items: center;
      text-align: center;
      &Container {
        row-gap: 14px;
      }
      &Title {
        position: relative;
        font-size: 24px;
        line-height: normal;
        span {
          position: absolute;
          top: -25px;
          right: 50%;
          transform: translate(50%);
        }
      }
      &SubTitle {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  .content {
    margin: 28px auto 40px;
    padding: 28px 16px;
  }
  .header {
    flex-direction: column;
    margin-bottom: 20px;
    &Container {
      align-items: center;
    }
    &Title {
      font-size: 20px;
      line-height: 28px;
    }
    &Button {
      width: 100%;
      padding: 8px 22px;
      align-self: center;
      font-size: 14px;
      margin-top: 20px;
      line-height: 25px;
    }
  }
}
