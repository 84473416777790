.dropdown {
  &Container {
    position: relative;
    display: inline-block;
  }
  &Toggle {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
  }
  &Options {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    background-color: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #2d3035;
    z-index: 1;
    padding: 12px 10px;
    text-align: left;
  }
  &Option {
    cursor: pointer;
    padding: 6px 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  &Option:hover {
    background-color: #f0f0f0;
  }
}
.closeModalIcon {
  align-self: flex-end;
  width: 28px;
  height: 28px;
  color: #04062c;
  cursor: pointer;
}
.modal {
  &Title {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #2d3035;
  }
  &Description {
    margin: 14px 0 40px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    align-self: center;
    text-align: center;
    color: #2d3035;
    max-width: 439px;
  }
  &ButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
  }
  &ButtonContainerSmall {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &CancelButton {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #2d3035;
    border: none;
    margin: 40px 0 20px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  &ConfirmButton {
    width: 210px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    background: #196faf;
    border-radius: 4px;
    border: none;
    padding: 14px 0px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #79abdb;
    }
  }
  &DownloadButton {
    width: 210px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    background: #74b566;
    border-radius: 4px;
    border: none;
    padding: 14px 0px;
    margin-right: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: #90c485;
    }
  }
}
@media screen and (max-width: 1020px) {
  .closeModalIcon {
    width: 20px;
    height: 20px;
  }
  .statusTitle {
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 30px;
  }
  .modal {
    &Title {
      font-size: 20px;
      line-height: 25px;
    }
    &Description {
      margin: 14px 0 30px;
      font-size: 14px;
      line-height: 20px;
      max-width: 300px;
    }
    &CancelButton {
      font-size: 14px;
      line-height: 17px;
      margin: 30px 0 20px;
    }
    &ConfirmButton {
      width: 190px;
      font-size: 14px;
      line-height: 17px;
    }
    &DownloadButton {
      width: 190px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
@media screen and (max-width: 840px) {
  .modal {
    &ConfirmButton {
      width: 150px;
    }
    &DownloadButton {
      width: 150px;
    }
  }
}
@media screen and (max-width: 768px) {
  .dropdown {
    &Options {
      font-size: 12px;
      padding: 8px;
    }
    &Option {
      padding: 4px 5px;
    }
  }
}
@media screen and (max-width: 420px) {
  .statusTitle {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .modal {
    &Title {
      font-size: 17px;
      line-height: 23px;
    }
    &Description {
      font-size: 12px;
      line-height: 16px;
      max-width: 200px;
    }
    &CancelButton {
      font-size: 12px;
      line-height: 15px;
      margin: 20px 0 10px;
    }
    &ConfirmButton {
      width: 130px;
      font-size: 12px;
      line-height: 15px;
    }
    &DownloadButton {
      width: 130px;
      font-size: 12px;
      line-height: 15px;
      margin-right: 10px;
    }
  }
}
