.resultTable {
  width: 100%;
}
.resultTable :global(.ant-table-content > table) {
  width: 100%;
  border-spacing: 0;
}
.resultTable :global(.ant-table-thead tr th)::before {
  display: none;
}
.resultTable :global(.ant-table-thead > tr > th) {
  background-color: #ffffff;
  color: #2d3035;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  padding: 16px 12px;
  border: none;
}
.resultTable :global(.ant-table-tbody > tr > td) {
  color: #2d3035;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  padding: 16px 12px;
  border: none;
  background-color: transparent;
}
.resultTable :global(.ant-table-tbody > tr:nth-child(even)) {
  background-color: #f8fafd;
}
.resultTable :global(.ant-table-tbody > tr) {
  background: #ffffff;
}
.resultTable :global(.ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover),
{
  background: transparent;
}
.addressContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
}
.addressText {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #5b6878;
  margin-left: 18px;
}
.infoSvgContainer {
  position: relative;
}
.hide {
  display: none;
}
.infoSvg > svg {
  width: 20px;
  height: 20px;
}
.infoSvg {
  svg {
    fill: #ff2f009c;
    transition: all 0.2s;
    &:hover {
      fill: #ff2e00;
    }
  }
}
.copySvg {
  width: 20px;
  height: 10px;
}
.hide {
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    right: 50%;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #5796d2;
  }
}
.infoSvg:hover + .hide {
  display: block;
  text-align: center;
  position: absolute;
  top: -70px;
  left: -160px;
  width: 350px;
  background: #5796d2;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  padding: 6px 8px;
}
.copySvgContainer {
  cursor: pointer;
  position: relative;
}
.copied {
  position: absolute;
  top: -35px;
  left: -30px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  background-color: #1b74b3;
  color: #fff;
  border-radius: 10px;
  padding: 5px 8px;
}

@media screen and (max-width: 1020px) {
  .resultTable :global(.ant-table-thead > tr > th) {
    font-size: 14px;
    line-height: 14px;
    padding: 10px 8px;
    margin: 0 6px;
  }
  .resultTable :global(.ant-table-tbody > tr > td) {
    font-size: 14px;
    line-height: 14px;
    padding: 10px 8px;
  }
  .addressText {
    font-size: 14px;
    line-height: 14px;
    width: 200px;
  }
  .infoSvg > svg {
    width: 16px;
    height: 16px;
  }
  .copySvg {
    width: 18px;
    height: 10px;
  }
  .infoSvg:hover + .hide {
    font-size: 12px;
    line-height: 20px;
    top: -34px;
  }
  .copied {
    font-size: 12px;
    line-height: 18px;
    padding: 5px 8px;
  }
}
@media screen and (max-width: 768px) {
  .resultTable :global(.ant-table-thead > tr > th) {
    background-color: #f8fafd;
  }
  .resultTable :global(.ant-table-tbody tr:nth-child(even)) > td {
    background: #ffffff;
  }
  .resultTable :global(.ant-table-tbody tr:nth-child(odd)) {
    background: #f8fafd;
  }
}

@media screen and (max-width: 420px) {
  .resultTable :global(.ant-table-thead > tr > th) {
    font-size: 12px;
    line-height: 12px;
    padding: 9px 6px;
    margin: 0 6px;
  }
  .resultTable :global(.ant-table-tbody > tr > td) {
    font-size: 12px;
    line-height: 12px;
    padding: 9px 86x;
  }
  .addressText {
    font-size: 12px;
    line-height: 12px;
  }
  .infoSvg > svg {
    width: 12px;
    height: 12px;
  }
  .copySvg {
    width: 14px;
    height: 10px;
  }
  .infoSvg:hover + .hide {
    font-size: 10px;
    line-height: 18px;
  }
  .copied {
    font-size: 10px;
    line-height: 16px;
    padding: 5px 8px;
  }
}
