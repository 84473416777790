body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  margin: 0;
  line-height: 42px;
}
