.item {
  &__Label {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    margin-bottom: 10px;
    color: #2d3035;
  }
}
.autocomplete {
  &Container {
    display: flex;
    column-gap: 5px;
  }
  &Input {
    border-radius: 4px;
    border: 1px solid #b3bcc9;
    color: #2d3035;
    font-size: 15px;
    font-weight: 400;
    line-height: 156.25%;
    padding: 10px 14px;
    width: 100%;
  }
}
:global(.pac-container) {
  border-radius: 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: 10px;
}
:global(.pac-item) {
  padding: 5px;
}
:global(.pac-item:first-child) {
  border: none;
}
.resetButton {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #b3bcc9;
  color: #b3bcc9;
  padding: 0px 5px;
  cursor: pointer;
  transition: all 0.3s;
  svg {
    width: 15px;
    height: 15px;
    transition: all ease-in-out 0.6s;
    path {
      transition: all 0.3s;
      fill: #b3bcc9;
    }
  }
  &:hover {
    border: 1px solid #2d3035;
    color: #2d3035;
    svg {
      -ms-transform: rotate(180deg); /* IE 9 */
      -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
      transform: rotate(180deg);
      path {
        fill: #2d3035;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .item {
    &__Label {
      font-size: 14px;
      line-height: normal;
    }
  }
  .autocomplete {
    &Input {
      font-size: 14px;
    }
  }
  .resetButton {
    column-gap: unset;
    color: #fff;
    font-size: 0.0001em;
    width: 43px;
  }
}
