.dropdownSingleContainer {
  position: relative;
}
.dropdownMultiContainer {
  position: relative;
}
.closeIcon {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  color: #04062c;
  cursor: pointer;
}
.modalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
}
.modalDescription {
  margin: 14px 0 40px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 300px;
}
.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 20px 20px;
}
.closeModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 14px 0px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
.confirmModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}

@media screen and (max-width: 1020px) {
  .modalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalDescription {
    margin: 14px 0 30px;
    font-size: 14px;
    line-height: 20px;
  }
  .modalButtonContainer {
    margin: 0 15px 10px 15px;
  }
  .closeModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
    margin-right: 0px;
  }
  .confirmModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 840px) {
  .closeModalButton {
    width: 150px;
  }
  .confirmModalButton {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .dropdownSingleContainer {
    grid-row: 2/3;
    grid-column: 4/5;
  }
}

@media screen and (max-width: 420px) {
  .modalTitle {
    font-size: 20px;
    line-height: 28px;
  }
  .modalDescription {
    margin: 14px 0 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .modalButtonContainer {
    margin: 0 5px 5px 5px;
  }
  .closeModalButton {
    width: 130px;
    font-size: 12px;
    line-height: 15px;
  }
  .confirmModalButton {
    width: 130px;
    font-size: 12px;
    line-height: 15px;
  }
}
