.line {
  display: block;
  position: relative;
  height: 2px;
  background-color: #f3f6f9;
  &Container {
    flex: 1 1 auto;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #5796d2;
    transition: width 0.5s linear;
  }
}
.activeLine {
  &::after {
    width: 100%;
  }
}
.stepperContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.step {
  width: 15px;
  height: 15px;
  border: 3px solid #f3f6f9;
  border-radius: 50%;
  background-color: #f3f6f9;
  transition: border 0.3s linear 0.5s, background-color 0.3s linear;
}
.step.done {
  transition: border 0.3s linear, background-color 0.3s linear;
}
.active {
  border: 3px solid #74b566;
  border-radius: 50%;
  background-color: inherit;
}
.done {
  border: 3px solid #5796d2;
  border-radius: 50%;
  background-color: #5796d2;
}
