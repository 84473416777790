.dropdownSvgContainer {
  display: flex;
  padding: 0 10px;
  cursor: pointer;
}
.closeModalIcon {
  align-self: flex-end;
  width: 28px;
  height: 28px;
  color: #04062c;
  cursor: pointer;
}
.modalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
}
.modalDescription {
  margin: 14px 0 40px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 439px;
}
.modalButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
}
.modalButtonContainerSmall {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalCancelButton {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  border: none;
  margin: 40px 0 20px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
.modalConfirmButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}
.modalDownloadButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #74b566;
  border-radius: 4px;
  border: none;
  padding: 14px 0px;
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #90c485;
  }
}
@media screen and (max-width: 1020px) {
  .closeModalIcon {
    width: 20px;
    height: 20px;
  }
  .statusTitle {
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 30px;
  }
  .modalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalDescription {
    margin: 14px 0 30px;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
  }
  .modalCancelButton {
    font-size: 14px;
    line-height: 17px;
    margin: 30px 0 20px;
  }
  .modalConfirmButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .modalDownloadButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 840px) {
  .modalConfirmButton {
    width: 150px;
  }
  .modalDownloadButton {
    width: 150px;
  }
}
@media screen and (max-width: 420px) {
  .statusTitle {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .modalTitle {
    font-size: 17px;
    line-height: 23px;
  }
  .modalDescription {
    font-size: 12px;
    line-height: 16px;
    max-width: 200px;
  }
  .modalCancelButton {
    font-size: 12px;
    line-height: 15px;
    margin: 20px 0 10px;
  }
  .modalConfirmButton {
    width: 130px;
    font-size: 12px;
    line-height: 15px;
  }
  .modalDownloadButton {
    width: 130px;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
  }
}
