.resultTable {
  &__address {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    padding-top: 200px;
  }
}
.infoSvgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-right: 20px;
}
.hide {
  display: none;
}
.infoSvg > svg {
  width: 20px;
  height: 20px;
}
.infoSvg:hover + .hide {
  width: 300px;
  display: block;
  text-align: center;
  position: absolute;
  top: -70px;
  left: -130px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
  background: #ffffff;
  padding: 6px 8px;
}
.resultTable__filter {
  display: none;
  padding: 15px 8px;
  width: 30%;
}
.resultTableMulti__filter {
  display: none;
  padding: 15px 8px;
  width: 40%;
}
.copySvg {
  width: 20px;
  height: 10px;
}

@media screen and (max-width: 768px) {
  .hide_lable {
    display: none;
  }
  .hide_item {
    display: none;
  }
  .resultTable__filter {
    display: flex;
    align-items: center;
  }
  .resultTableMulti__filter {
    display: flex;
    align-items: center;
  }
  .resultTable__message {
    font-size: 14px;
    padding-top: 100px;
  }
  .copySvg {
    width: 14px;
    height: 10px;
  }
}
