.content {
  margin-top: 60px;
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Title {
    font-weight: 500;
    font-size: 24px;
    line-height: 142%;
    color: #2d3035;
  }
  &Total {
    font-weight: 400;
    font-size: 18px;
    line-height: 178%;
    color: #2d3035;
  }
}

.addressData {
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 61px;
  column-gap: 70px;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
  width: 100%;
  &Item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  &Amount {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #2d3035;
  }
  &Description {
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    text-align: center;
    color: #717780;
  }
}

.buttonContainer {
  margin-top: 50px;
  display: flex;
}
.copyButton {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 12px;
  background: #b3bcc9;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: 0.3s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: #c2c9d4;
  }
  &:disabled {
    background: #d2d5d8;
    cursor: not-allowed;
    border: 1px solid #d2d5d8;
    color: #ffffff;
  }
}
.downloadButton {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 12px;
  background: #74b566;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: #90c485;
  }
  &:disabled {
    background: #b9dab2;
    cursor: not-allowed;
    border: 1px solid #d2d5d8;
  }
}

.sorted {
  &Container {
    margin-top: 50px;
  }
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 142%;
    color: #2d3035;
  }
  &Filter {
    padding: 6px 12px;
    background: #d2dbe8;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 156%;
    color: #2d3035;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #c2c9d4;
    }
    &Menu {
      display: none;
      z-index: 1000;
      position: absolute;
      right: 0;
      top: 50px;
      &IsOpen {
        display: block;
      }
    }
  }
  &Data {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &Headers {
    background: #f3f6f9;
    &Item {
      text-align: center;
      padding: 16px 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 156%;
      color: #2d3035;
    }
  }
  &Items {
    &:nth-child(odd) {
      background: #f3f6f9;
    }
  }
  &Item {
    position: relative;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #2d3035;
    width: 175px;
    padding: 16px 10px;
    &:hover .hide {
      display: block;
      text-align: center;
      position: absolute;
      top: -50px;
      left: 0px;
      width: 250px;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #2d3035;
      padding: 6px 8px 0 8px;
    }
  }
}

.hide {
  display: none;
}

.hide {
  &Triangle {
    width: 0px;
    height: 0px;
    position: relative;
    left: 105px;
    top: 8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
  }
}
.mapContainer {
  margin-top: 30px;
  img {
    width: 100%;
  }
}
