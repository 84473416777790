@import '../../../assets/scss/variables';

.container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.grayContainer {
  background: #f8fafd;
}
.contentHeader {
  margin-top: 126px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  &Container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &Title {
    font-weight: 700;
    font-size: 32px;
    line-height: 175%;
    color: #2d3035;
  }
  &SubTitle {
    font-weight: 400;
    font-size: 22px;
    color: #2d3035;
  }
  &Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 179%;
    text-align: center;
    white-space: nowrap;
    color: #5796d2;
    padding: 8px 12px;
    border: 1.5px solid #5796d2;
    border-radius: 4px;
    transition: filter 0.35s ease-in-out;
    svg {
      path {
        fill: #5796d2;
      }
    }
    &:hover {
      filter: brightness(1.1);
    }
    &Container {
      display: flex;
      column-gap: 12px;
      align-self: flex-start;
      column-gap: 10px;
      margin-top: 8px;
    }
    &Feedback {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 179%;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      color: #ffffff;
      padding: 8px 12px;
      border: 1.5px solid #5796d2;
      background-color: #5796d2;
      border-radius: 4px;
      transition: filter 0.35s ease-in-out;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}
.content {
  margin: 30px auto 65px;
  padding: 44px 36px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.input, .input:focus {
  display: flex;
  align-items: center;
  align-self: flex-start;
  border: none;
  border-radius: 0;
  padding: 0;
  border-bottom: 1px solid #d2dbe8 !important;
  box-shadow: none !important;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #717780;
  input {
    width: 300px;
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
    &::placeholder {
      color: #717780;
    }
  }
}
.batchTitle {
  &Container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  &Description {
    color: #717780;
    font-size: 14px;
    font-weight: 400;
    line-height: 114.286%;
  }
}
:global(.ant-radio-checked .ant-radio-inner) {
  border-color: rgba(45, 48, 53, 1) !important;
  background-color: rgba(45, 48, 53, 1) !important;
}
.ant-radio-checked .ant-radio-inner:after{
  background-color: rgba(45, 48, 53, 1);
}
:global(.ant-radio-wrapper:hover .ant-radio-inner) {
  border-color: rgba(45, 48, 53, 1);
}

.resetReportButton {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  box-sizing: border-box;
  width: 190px;
  height: 52px;
  border: 2px solid #b3bcc9;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  text-align: center;
  color: #717780;
  transition: filter 0.35s ease-in-out;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    filter: brightness(0.8);
  }
}
.buildReportButton {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  width: 210px;
  height: 52px;
  color: #ffffff;
  background-color: #ef6f22;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f28c4e;
  }
  &:disabled {
    cursor: not-allowed;
    background: #f7b790;
  }
}
.buildReportButtonContainer {
  display: flex;
  column-gap: 20px;
}
.buildReport {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buildReportHint {
  position: relative;
}
.hide {
  display: none;
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #5796d2;
  }
}
.buildReportButton:hover:disabled + .hide {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 65px;
  left: -65px;
  width: 350px;
  background: #5796d2;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  padding: 6px 8px;
}
.container :global(.ant-input-suffix > svg) {
  width: 22px;
  height: 22px;
}
.input :global(.ant-input-suffix > svg) {
  width: 28px;
  height: 28px;
}

.creditBalance {
  font-weight: 400;
  color: #2d3035;
  &Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
    font-size: 16px;
  }
  &Buy {
    color: #5796d2;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.4s;
    &:hover {
      color: #196faf;
    }
  }
}

.dropzoneContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.infoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 17px 0px 24px 0px;
}

.infoIcon {
  svg {
    fill: #5796d2;
    width: 18px;
    height: 18px;
    transition: all 0.2s;
    &:hover {
      fill: #b3bcc9;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: #04062c;
}
.infoDescription {
  color: #717780;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 157%;
}
.infoDescriptionWithHint {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
}
.infoDownload {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 179%;
  text-decoration-line: underline;
  color: #196faf;
}
.uploadedFileContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  column-gap: 18px;
}
.uploadedFiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 18px;
}
.addAnotherButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fileContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7.5px 12px;
  background: rgba(91, 104, 120, 0.12);
  border-radius: 8px;
}
.fileName {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 179%;
  color: #2d3035;
  margin-right: 21px;
}
.removeFileIcon {
  cursor: pointer;
  color: #5b6878;
  width: 20px;
  height: 20px;
}
.reloadFileIcon {
  color: #1b74b3;
  font-size: 20px;
}
.uploadAnotherButton {
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  display: flex;
  align-items: center;
  color: #196faf;
  margin-left: 6px;
}

.tableContainer {
  max-width: 1248px;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.globalFiltersContainer {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  margin: 0px 0px 24px 0px;
}
.globalFiltersEdit {
  align-self: flex-end;
  align-self: flex-end;
  display: flex;
  column-gap: 22px;
  justify-content: center;
  align-items: center;
  &List {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background: #5796d2;
    border-radius: 3px;
    padding: 11px 12px;
    transition: all 0.3s;
    &:hover {
      background: #79abdb;
    }
  }
  &Cancel {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background: #b3bcc9;
    border-radius: 3px;
    padding: 11px 12px;
    transition: all 0.3s;
    &:hover {
      background: #b3bcc9c7;
    }
  }
}
.clearButtonContainer {
  display: flex;
}
.clearSelectionButton {
  display: flex;
  align-items: center;
  align-self: flex-start;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
.clearSelectionText {
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #196faf;
  margin-left: 10px;
}
.lookBackContainer,
.buildingTypeContainer,
.noteContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lookBackContainer {
  margin-bottom: 16px;
}
.lookBackText,
.buildingTypeText {
  margin-right: 24px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #2d3035;
}
.filtersTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
}
.filtersTitle {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: #2d3035;
  margin: 0;
  text-align: left;
  margin-right: 14px;
}
.noteText {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #04062c;
}
.hideIcon {
  display: none;
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    right: 50%;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #5796d2;
  }
}
.infoIcon:hover + .hideIcon {
  display: block;
  text-align: center;
  position: absolute;
  top: -70px;
  left: -23px;
  width: 300px;
  background: #5796d2;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  padding: 6px 8px;
}

.infoContainerHint {
  display: flex;
  position: relative;
}
.infoIcon1 {
  svg {
    fill: #5796d2;
    width: 16px;
    height: 16px;
    transition: all 0.2s;
    &:hover {
      fill: #b3bcc9;
    }
  }
}
.hideIcon1 {
  display: none;
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    right: 50%;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #5796d2;
  }
}
.infoIcon1:hover + .hideIcon1 {
  display: block;
  text-align: left;
  position: absolute;
  bottom: 25px;
  left: -137px;
  width: 300px;
  background: #5796d2;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  padding: 6px 8px;
  ul {
    list-style-position: inside;
    li {
      list-style: none;
      color: #fff;
    }
    li:before {
      content: '•';
      vertical-align: middle;
      margin-right: 8px;
      margin-left: 8px;
      color: #fff;
    }
  }
}
.globalFiltersContainer :global(.ant-radio-group) {
  display: flex;
}
.globalFiltersContainer :global(.ant-radio-wrapper) {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #2d3035;
  margin-right: 24px;
  display: flex;
  align-items: center;
}
.globalFiltersContainer :global(.ant-radio-wrapper > .ant-radio-checked > .ant-radio-input),
.globalFiltersContainer :global(.ant-radio-wrapper > .ant-radio > .ant-radio-input) {
  accent-color: #2d3035;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  cursor: pointer;
}
.globalFiltersContainer :global(.ant-radio) {
  display: flex;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.modalTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  font-family: 'Roboto';
  color: #2d3035;
}
.closeModalIcon {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  color: #04062c;
  cursor: pointer;
}
.modalDescription {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2d3035;
  max-width: 612px;
  margin: 20px 0 30px;
}
.modalDescriptionAdditional {
  display: block;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2d3035;
  max-width: 612px;
  margin: 10px 0 14px 0;
}
.apiModalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.apiModalTitle {
  align-self: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  font-family: 'Roboto';
  color: #2d3035;
  text-align: center;
}
.apiModalDescription {
  align-self: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2d3035;
  max-width: 552px;
  margin: 14px 0 17px;
  text-align: center;
}
.headersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
}
.prefferedHeadersContainer {
  display: flex;
  flex-direction: column;
}
.modalArrowContainer {
  display: flex;
  flex-direction: column;
  margin: 0 14px;
  justify-content: space-around;
  svg:first-child {
    path {
      fill: white;
    }
  }
}
.headerInput,
.additionalTitle {
  background: rgba(154, 169, 187, 0.2) !important;
  border-radius: 4px;
  padding: 14px 8px;
  border: none;
  outline: none;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  color: #2d3035 !important;
  width: 280px;
  height: 48px;
  &:focus {
    border: none;
    outline: none;
  }
}
.disabledInput {
  background: #ffffff !important;
  border: 1px solid #b3bcc9 !important;
  border-radius: 4px;
  padding: 10px 14px 9px;
  outline: none;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #717780 !important;
  width: 280px;
  margin-top: 10px;
  &:focus {
    border: none;
    outline: none;
  }
}
.apiModalInput {
  min-width: 460px;
  background: #ffffff;
  border: 1px solid #b3bcc9;
  border-radius: 4px;
  padding: 10px 14px 9px;
  outline: none;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #717780;
  width: 100%;
  margin-top: 10px;
  &:hover {
    border: 1px solid #b3bcc9;
  }
  &:focus {
    border: 1px solid #b3bcc9;
    box-shadow: none;
  }
}
.fileHeadersContainer {
  display: flex;
  flex-direction: column;
}
.additional {
  &Headers {
    width: 100%;
    max-width: 612px;
  }
  &Title {
    width: 100%;
  }
  &Item {
    width: 100%;
    margin-top: 10px;
  }
  &Content {
    margin-bottom: 20px;
  }
  &Description {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #717780;
  }
  &AddButton {
    cursor: not-allowed;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 156%;
    display: flex;
    align-items: center;
    color: #c24040;
    margin-left: 6px;
  }
}
.suffix {
  &Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;
  }
  &Text {
    align-self: flex-end;
    font-size: 12px;
    font-weight: 500;
    color: #196faf;
    margin-left: -4px;
  }
}
// Process modal
.processModalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
  margin-bottom: 20px;
}
.processModalDescription {
  margin: 10px 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 330px;
}
.processModalDescriptionText {
  margin: 10px 0 20px 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
}
.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.closeProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  background: #ffffff;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
  }
}
.confirmProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.confirmModalButton {
  margin-top: 30px;
  width: 210px;
  background-color: #196faf;
  border-radius: 4px;
  padding: 14px 0px 13px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  align-self: center;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #79abdb;
  }
}
.backBtn {
  background-color: #fff;
  color: #717780;
  border: 1px solid #b3bcc9;
  transition: background-color 0.3s;
  &:hover {
    background-color: #eae8e8;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #edededc7;
  }
}
.headerSelect {
  margin-top: 10px;
}
// Delete modal
.closeDeleteModalIcon {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  color: #04062c;
  cursor: pointer;
}
.deleteModalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
}
.deleteModalDescription {
  margin: 14px 0 40px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 268px;
}
.deleteModalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 20px 20px;
}
.closeDeleteModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding: 14px 0px;
  margin-right: 20px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
.confirmDeleteModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}

.modalDescriptionFirst {
  width: 610px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 140.188%;
  color: #2d3035;
  max-width: 612px;
  margin: 20px 0 30px;
  ul {
    list-style-position: inside;
    li {
      list-style: none;
    }
    li:before {
      content: '•';
      vertical-align: middle;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
}

.modalButtonFirst {
  align-self: center;
  margin-top: 40px;
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #79abdb;
  }
}

.modalFirstLabel {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.modalButtonSkip {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #196faf;
  margin-top: 40px;
  margin-bottom: 10px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1020px) {
  .content {
    margin: 30px auto 50px;
    padding: 1.5rem 1.3rem;
  }
  .input {
    input {
      font-size: 28px;
      line-height: 32px;
      max-width: 300px;
    }
  }
  .infoContainer {
    margin-top: 10px;
    align-items: center;
  }
  .infoIcon {
    padding-right: 4px;
  }
  .infoDescription {
    font-size: 12px;
    line-height: 16px;
    padding-right: 4px;
  }
  .infoDownload {
    font-size: 12px;
    line-height: 16px;
  }
  .buildReportButton {
    padding: 14px 75px;
  }
  .buildReportButton:hover:disabled + .hide {
    top: 0;
    left: -110px;
    width: 250px;
    font-size: 12px;
    line-height: 14px;
    padding: 6px;
  }
  .infoIcon:hover + .hide {
    top: -35px;
    font-size: 12px;
    line-height: 14px;
    padding: 6px;
  }
  .lookBackContainer,
  .buildingTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .noteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .lookBackText,
  .buildingTypeText {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 4px;
  }
  .filtersTitle {
    font-size: 14px;
    line-height: 17px;
  }
  .noteText {
    font-size: 11px;
    line-height: 14px;
  }
  .globalFiltersContainer :global(.ant-radio-wrapper) {
    font-size: 14px;
    line-height: 17px;
  }
  .globalFiltersContainer :global(.ant-radio-wrapper > .ant-radio-checked > .ant-radio-input),
  .globalFiltersContainer :global(.ant-radio-wrapper > .ant-radio > .ant-radio-input) {
    height: 14px;
    width: 14px;
    margin-right: 6px;
  }
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .processModalDescription {
    font-size: 16px;
    line-height: 20px;
    max-width: 330px;
  }
  .closeProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .confirmProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 900px) {
  .titleContainer {
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
  }
  .input {
    align-self: center;
  }
}

@media screen and (max-width: 840px) {
  .input {
    input {
      font-size: 24px;
      line-height: 32px;
      max-width: 300px;
    }
  }
  .input :global(.ant-input-suffix > svg) {
    width: 24px;
    height: 24px;
  }
  .globalFiltersEdit {
    &List {
      font-size: 14px;
      padding: 6px;
    }
  }
  .fileContainer {
    padding: 8px 6px 8px 8px;
  }
  .uploadedFiles {
    flex-direction: column;
  }
  .modalDescription {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #04062c;
    max-width: 300px;
    margin-bottom: 16px;
  }
  .headersContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
  }
  .prefferedHeadersContainer {
    display: flex;
    flex-direction: column;
  }
  .modalArrowContainer {
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    justify-content: space-around;
  }
  .headerInput {
    padding: 10px 8px;
    font-size: 16px;
    line-height: 25px;
    width: 150px;
  }
  .disabledInput {
    padding: 10px 10px 9px;
    font-size: 16px;
    line-height: 25px;
    width: 150px;
  }
  .apiModalInput {
    padding: 10px 10px 9px;
    font-size: 16px;
    line-height: 25px;
  }
  .fileHeadersContainer {
    display: flex;
    flex-direction: column;
  }
  .confirmModalButton {
    width: 190px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    &Container {
      padding: 0 20px;
    }
    &Header {
      margin-top: 110px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 24px;
      text-align: center;
      &Container {
        row-gap: 14px;
      }
      &Title {
        position: relative;
        font-size: 24px;
        line-height: normal;
        span {
          position: absolute;
          top: -25px;
          right: 50%;
          transform: translate(50%);
        }
      }
      &SubTitle {
        font-size: 14px;
        line-height: normal;
      }
      &Btn {
        width: 100%;
        font-weight: 700;
        line-height: 25px;
        padding: 8px 12px;
        background-color: #ffffff;
        &Container {
          width: 100%;
          column-gap: 12px;
          align-self: unset;
          margin-top: unset;
        }
        &Feedback {
          width: 100%;
          font-weight: 700;
          line-height: 25px;
          padding: 8px 12px;
        }
      }
    }
  }
  .content {
    margin: 28px auto 40px;
    padding: 28px 16px;
  }
  .globalFiltersContainer {
    flex-direction: column;
  }
  .creditBalance {
    &Container {
      font-size: 11px;
    }
  }
  .resetReportButton {
    width: 170px;
    height: 45px;
    font-size: 14px;
    padding: 0;
  }
  .buildReportButton {
    width: 170px;
    height: 45px;
    padding: 0;
    font-size: 14px;
  }
  .closeProcessModalButton {
    width: 150px;
  }
  .confirmProcessModalButton {
    width: 150px;
  }
}
@media screen and (max-width: 510px) {
  .contentHeader {
    flex-direction: column;
    row-gap: 15px;
    text-align: center;
    align-items: center;
    &Btn {
      &Container {
        align-self: auto;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .content {
    margin: 30px auto 30px;
    padding: 1rem 0.8rem;
  }
  .input {
    input {
      font-size: 22px;
      line-height: 26px;
      max-width: 200px;
    }
  }
  .container :global(.ant-input-suffix > svg) {
    width: 18px;
    height: 18px;
  }
  .buildReportButtonContainer {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
  .infoContainer {
    margin-top: 10px;
    align-items: center;
  }
  .infoContainer > svg {
    width: 16px;
    height: 16px;
  }
  .infoIcon {
    padding-right: 4px;
  }
  .infoDescription {
    font-size: 10px;
    line-height: 14px;
    padding-right: 4px;
  }
  .infoDownload {
    font-size: 10px;
    line-height: 14px;
  }

  .fileContainer {
    padding: 8px 6px 8px 8px;
  }
  .removeFileIcon {
    font-size: 16px;
  }
  .reloadFileIcon {
    font-size: 16px;
  }
  .buildReportButton {
    padding: 8px 70px;
  }
  .uploadedFiles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .fileName {
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
  }
  .uploadAnotherButton {
    font-size: 12px;
    line-height: 15px;
    margin-left: 12px;
  }
  .lookBackContainer,
  .buildingTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .noteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .lookBackText,
  .buildingTypeText {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 4px;
  }
  .filtersTitle {
    font-size: 12px;
    line-height: 14px;
  }
  .noteText {
    font-size: 10px;
    line-height: 12px;
  }
  .globalFiltersContainer :global(.ant-radio-wrapper) {
    font-size: 12px;
    line-height: 15px;
  }
  .globalFiltersContainer :global(.ant-radio-wrapper > .ant-radio-checked > .ant-radio-input),
  .globalFiltersContainer :global(.ant-radio-wrapper > .ant-radio > .ant-radio-input) {
    height: 12px;
    width: 12px;
    margin-right: 6px;
  }
  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .modalDescription {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #04062c;
    max-width: 300px;
    margin-bottom: 16px;
  }
  .headersContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
  }
  .prefferedHeadersContainer {
    display: flex;
    flex-direction: column;
  }
  .modalArrowContainer {
    display: flex;
    flex-direction: column;
    margin: 0 3px;
    justify-content: space-around;
  }
  .headerInput {
    font-size: 10px;
    width: 100px;
  }
  .disabledInput {
    font-size: 10px;
    line-height: 16px;
    width: 100px;
  }
  .apiModalInput {
    font-size: 10px;
    line-height: 16px;
  }
  .fileHeadersContainer {
    display: flex;
    flex-direction: column;
  }
  .confirmModalButton {
    width: 170px;
    font-size: 12px;
    line-height: 18px;
  }
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalButtonContainer {
    margin: 5px;
  }
  .closeProcessModalButton {
    width: 130px;
  }
  .confirmProcessModalButton {
    width: 130px;
  }
}
