.container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.grayContainer {
  background: #f8fafd;
}
.contentHeader {
  margin-top: 126px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  &Container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &Title {
    font-weight: 700;
    font-size: 32px;
    line-height: 175%;
    color: #2d3035;
  }
  &SubTitle {
    font-weight: 400;
    font-size: 22px;
    color: #2d3035;
  }
  &Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 179%;
    text-align: center;
    white-space: nowrap;
    color: #5796d2;
    padding: 8px 12px;
    border: 1.5px solid #5796d2;
    border-radius: 4px;
    transition: filter 0.35s ease-in-out;
    svg {
      path {
        fill: #5796d2;
      }
    }
    &:hover {
      filter: brightness(1.1);
    }
    &Container {
      display: flex;
      column-gap: 12px;
      align-self: flex-start;
      column-gap: 10px;
      margin-top: 8px;
    }
    &Feedback {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 179%;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      color: #ffffff;
      padding: 8px 12px;
      border: 1.5px solid #5796d2;
      background-color: #5796d2;
      border-radius: 4px;
      transition: filter 0.35s ease-in-out;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}
.content {
  margin: 30px auto 65px;
  padding: 44px 36px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &Container {
    display: flex;
    flex-direction: column;
  }
  &Buttons {
    display: flex;
    align-self: start;
    column-gap: 20px;
  }
  &Button {
    width: 200px;
    padding: 12px 0px;
    background: #ef6f22;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 156%;
    text-align: center;
    color: #ffffff;
    transition: 0.3s;
    &:hover {
      background-color: #f28c4e;
    }
    &:disabled {
      background-color: #f7b790;
    }
  }
  &ButtonView {
    box-sizing: border-box;
    width: 200px;
    padding: 12px 0px;
    border: 2px solid #b3bcc9;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 156%;
    text-align: center;
    color: #717780;
    transition: 0.3s;
    &:hover {
      color: #5d5f64;
      border-color: #656974;
    }
    &:disabled {
      cursor: not-allowed;
      color: #d2d5d8;
      border-color: #d2d5d8;
    }
  }
}
.statusTitle {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #2d3035;
  text-align: left;
  margin-bottom: 30px;
}
.buttonContainer {
  display: flex;
}
.copyButton,
.deleteButton {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 12px;
  background: #b3bcc9;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: 0.3s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: #c2c9d4;
  }
  &:disabled {
    background: #d2d5d8;
    cursor: not-allowed;
    border: 1px solid #d2d5d8;
    color: #ffffff;
  }
}
.deleteButton {
  margin-right: 0px;
  svg {
    path:first-child {
      fill: none;
    }
  }
}
.downloadButton {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 12px;
  background: #74b566;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: #90c485;
  }
  &:disabled {
    background: #b9dab2;
    cursor: not-allowed;
    border: 1px solid #d2d5d8;
  }
}

// Modal
.closeModalIcon {
  align-self: flex-end;
  width: 28px;
  height: 28px;
  color: #04062c;
  cursor: pointer;
}
.modal {
  &Title {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #2d3035;
  }
  &Description {
    margin: 14px 0 40px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    align-self: center;
    text-align: center;
    color: #2d3035;
    max-width: 439px;
  }
  &ButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
  }
  &ButtonContainerSmall {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &CancelButton {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #2d3035;
    border: none;
    margin: 40px 0 20px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  &ConfirmButton {
    width: 210px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    background: #196faf;
    border-radius: 4px;
    border: none;
    padding: 14px 0px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #79abdb;
    }
  }
  &DownloadButton {
    width: 210px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    background: #74b566;
    border-radius: 4px;
    border: none;
    padding: 14px 0px;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #90c485;
    }
  }
}

@media screen and (max-width: 1024px) {
  .headerButton,
  .headerButtonView {
    width: 170px;
    padding: 10px 0;
    font-size: 14px;
  }
  .content {
    margin: 30px auto 50px;
    padding: 1.5rem 1.3rem;
  }
  .closeModalIcon {
    width: 20px;
    height: 20px;
  }
  .statusTitle {
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 30px;
  }
  .modalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalDescription {
    margin: 14px 0 30px;
    font-size: 14px;
    line-height: 20px;
    max-width: 300px;
  }
  .modalCancelButton {
    font-size: 14px;
    line-height: 17px;
    margin: 30px 0 20px;
  }
  .modalConfirmButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .modalDownloadButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    &Container {
      padding: 0 20px;
    }
    &Header {
      margin-top: 110px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 24px;
      text-align: center;
      &Container {
        row-gap: 14px;
      }
      &Title {
        position: relative;
        font-size: 24px;
        line-height: normal;
        span {
          position: absolute;
          top: -25px;
          right: 50%;
          transform: translate(50%);
        }
      }
      &SubTitle {
        font-size: 14px;
        line-height: normal;
      }
      &Btn {
        width: 100%;
        font-weight: 700;
        line-height: 25px;
        padding: 8px 12px;
        background-color: #ffffff;
        &Container {
          width: 100%;
          column-gap: 12px;
          align-self: unset;
          margin-top: unset;
        }
        &Feedback {
          width: 100%;
          font-weight: 700;
          line-height: 25px;
          padding: 8px 12px;
        }
      }
    }
  }
  .content {
    margin: 28px auto 40px;
    padding: 28px 16px;
  }
  .statusTitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .header {
    flex-direction: column;
    margin-bottom: 20px;
    &Container {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    &Buttons {
      width: 100%;
      align-self: center;
    }
    &Button,
    &ButtonView {
      width: 100%;
      margin-top: 20px;
    }
  }
  .buttonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: unset;
    align-items: unset;
    gap: 10px;
    width: 100%;
  }
  .deleteButton,
  .copyButton {
    color: #5796d2;
    padding: 8px 12px;
    background: #ffffff;
    border: 1.5px solid #5796d2;
    svg {
      path {
        fill: #5796d2;
      }
    }
  }
  .deleteButton,
  .copyButton,
  .downloadButton {
    justify-content: center;
    margin-right: unset;
    width: 100%;
  }
  .downloadButton {
    grid-column: 1/3;
  }
}
@media screen and (max-width: 420px) {
  .modal {
    &Title {
      font-size: 17px;
      line-height: 23px;
    }
    &Description {
      font-size: 12px;
      line-height: 16px;
      max-width: 200px;
    }
    &CancelButton {
      font-size: 12px;
      line-height: 15px;
      margin: 20px 0 10px;
    }
    &ConfirmButton {
      width: 130px;
      font-size: 12px;
      line-height: 15px;
    }
    &DownloadButton {
      width: 130px;
      font-size: 12px;
      line-height: 15px;
      margin-right: 10px;
    }
  }
}
