.faq {
  padding: 70px 0 80px 0;
  &Header {
    color: #196faf;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 155.556%;
    margin-bottom: 14px;
  }
  &Link {
    color: #196faf;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &Description {
    color: #2d3035;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 177.778%;
    margin-bottom: 50px;
    a {
      color: #196faf;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &Item {
    border-bottom: 1px solid #cfd3d7;
  }
  &Question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    color: #2d3035;
    font-size: 18px;
    font-weight: 500;
    line-height: 177.778%;
    padding: 16px 0;
    &:after {
      content: '';
      float: right;
      margin-right: 7px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #717780;
      transition: all 0.3s;
    }
  }
  &Expanded::after {
    content: '';
    transform: rotate(180deg);
  }
  &Answer {
    max-width: 860px;
    color: #2d3035;
    font-size: 16px;
    font-weight: 400;
    line-height: 156.25%;
    padding-bottom: 16px;
    &Container {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.35s;
    }
    &Container.expanded {
      max-height: 500px;
    }
    ul,
    ol {
      list-style-position: inside;
      margin-bottom: 15px;
    }
    a {
      color: #196faf;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &Table {
    max-width: 641px;
    max-height: 250px;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .faq {
    padding: 32px 0;
    &Header {
      font-size: 24px;
      line-height: normal;
    }
    &Description {
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 28px;
      a {
        font-size: 14px;
      }
    }
    &Question {
      font-size: 14px;
      line-height: 22px;
      padding: 16px 8px;
    }
    &Answer {
      font-size: 14px;
      line-height: 23px;
      a {
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
}
