.closeModalIcon {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  color: #04062c;
  cursor: pointer;
}

.processModalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
  margin-bottom: 20px;
}
.processModalDescription {
  margin: 10px 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 268px;
}
.processModalDescriptionText {
  margin: 12px 0 20px 0;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 23.44px;
  align-self: center;
  text-align: center;
  color: #2d3035;
}
.processModalAdditionalText {
  margin-bottom: 24px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
}
.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.closeProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #5796D2;
  background: #ffffff;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
  }
}
.confirmProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}
.confirmModalButton {
  margin-top: 30px;
  width: 210px;
  background: #196faf;
  border-radius: 4px;
  padding: 14px 0px 13px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  align-self: center;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #79abdb;
  }
}

@media screen and (max-width: 1020px) {
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .processModalDescription {
    font-size: 16px;
    line-height: 20px;
    max-width: 330px;
  }
  .closeProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .confirmProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 420px) {
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalButtonContainer {
    margin-top: 5px;
  }
  .closeProcessModalButton {
    width: 130px;
  }
  .confirmProcessModalButton {
    width: 130px;
  }
}
