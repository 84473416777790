.tableItems {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2d3035;
  margin-bottom: 56px;
  &:last-child {
    margin-bottom: 0px;
  }
  &Number {
    width: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 178%;
    color: #303134;
    margin-right: 20px;
    text-align: left;
  }
  &Description {
    position: relative;
    width: 12%;
    font-weight: 400;
    margin-right: 25px;
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
      &Bottom {
        position: absolute;
        top: 42px;
        left: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #717780;
      }
    }
    &Input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 14px 10px 14px;
    }
  }
  &Bed {
    position: relative;
    width: 12%;
    font-weight: 400;
    margin-right: 25px;
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &Bath {
    position: relative;
    width: 17.5%;
    font-weight: 400;
    margin-right: 100px;
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &Count {
    position: relative;
    width: 12%;
    font-weight: 400;
    margin-right: 20px;
    &Input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 14px 10px 14px;
    }
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &Address {
    position: relative;
    width: 51%;
    font-weight: 400;
    margin-right: 25px;
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
    &Input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 50px 10px 14px;
    }
    &Image {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 11px;
      top: 8px;
    }
  }
  &Bedrooms {
    position: relative;
    width: 12%;
    font-weight: 400;
    margin-right: 25px;
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &CurrentRent {
    position: relative;
    width: 12%;
    font-weight: 400;
    margin-right: 25px;
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
    &InputContainer {
      position: relative;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
    &Input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 14px 10px 28px;
    }
    &Placeholder {
      position: absolute;
      top: 6px;
      left: 11px;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #717780;
    }
    &PlaceholderSqft {
      position: absolute;
      top: 6px;
      left: 11px;
      font-weight: 400;
      font-size: 13px;
      line-height: 25px;
      color: #717780;
    }
  }
  &Bathrooms {
    position: relative;
    width: 12%;
    font-weight: 400;
    margin-right: 20px;
    &Label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &Additional {
    cursor: pointer;
    padding: 0 5px;
  }
}

@media screen and (max-width: 1024px) {
  .item {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .item {
    display: grid;
    grid-template-columns: 21px repeat(2, minmax(0, 1fr)) 20px;
    grid-auto-rows: 80px;
    gap: 15px;
    font-size: 14px;
    &Description {
      width: 100%;
      grid-column: 2 / 3;
      &Label {
        bottom: 45px;
        &Bottom {
          font-size: 9px;
        }
      }
    }
    &Bed {
      width: 100%;
      grid-column: 3 / 4;
      max-width: 200px;
      &Label {
        bottom: 45px;
      }
    }
    &Bath {
      width: 100%;
      grid-column: 2 / 3;
      max-width: 200px;
      &Label {
        bottom: 45px;
      }
    }
    &Count {
      width: 100%;
      grid-column: 3 / 4;
      max-width: 200px;
      &Label {
        bottom: 45px;
      }
    }
    &Address {
      width: 100%;
      grid-column: 2 / 5;
      grid-row: 1 / 2;
      &Label {
        bottom: 45px;
      }
    }
    &Bedrooms {
      width: 100%;
      grid-column: 2 / 3;
      max-width: 200px;
      &Label {
        bottom: 45px;
      }
    }
    &Bathrooms {
      width: 100%;
      grid-column: 3 / 4;
      position: relative;
      max-width: 200px;
      &Label {
        bottom: 45px;
      }
    }
    &CurrentRent {
      width: 100%;
      grid-column: 2/4;
      grid-row: 3 / 4;
      position: relative;
      &Label {
        bottom: 45px;
      }
    }
  }
  .popup-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
}
