// Normilize
* {
  font-family: 'Roboto';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  font: inherit;
  outline: inherit;
}

// Styles container
.container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
  color: #303134;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

._container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

// Toggle
.toggle-container {
  margin: 136px auto 21px auto;
  width: 88%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    text-align: center;
    justify-content: space-between;
  }
}

.toggle__family {
  display: flex;
}

.toggle_btn {
  font-family: 'Roboto';
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5796d2;
  background: #eff5fb;
  border-radius: 5px;
  text-align: center;
  width: 180px;
  padding: 7px 0;
  &-active {
    color: #ffffff;
    background: #5796d2;
    font-weight: 500;
  }
  &:hover {
    opacity: 0.85;
  }
}

//History
.history-container {
  display: none;
}

// Description
.description-container {
  margin: 0 auto 50px auto;
  padding: 30px 60px;
  width: 88%;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  text-align: left;
  border: 1px solid #d2dbe8;
}

.description__item {
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #303134;
  & span {
    font-weight: 500;
  }
  &-small {
    width: 40%;
    margin-right: 50px;
  }
  &-big {
    width: 60%;
  }
}

// Table
.content {
  margin: 0 auto 30px auto;
  padding: 41px 60px 50px;
  width: 88%;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #d2dbe8;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &__addUnit {
    margin-left: 35px;
    margin-top: 30px;
    text-align: left;
    display: flex;
    align-items: center;
    &-disabled {
      margin-left: 0;
      &-text {
        text-align: left;
        margin-left: 6px;
        font-weight: 500;
        font-size: 16px;
        color: #c24040;
      }
    }
    & img {
      cursor: pointer;
    }
    &-text {
      cursor: pointer;
      margin-left: 6px;
      font-weight: 500;
      font-size: 16px;
      color: #196faf;
    }
  }
  &__name {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
    text-align: start;
    &-input {
      width: 100%;
      height: 38px;
      border: none;
      border-bottom: 1px solid rgba(210, 219, 232, 1);
      padding-bottom: 8px;
      margin-bottom: 8px;
      font-size: 26px;
      font-weight: 400;
      color: rgba(45, 48, 53, 1);
    }
    &-image {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 0;
      top: 0;
      color: rgba(113, 119, 128, 1);
    }
    &-helper {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: rgba(113, 119, 128, 1);

      &.error {
        color: red;
      }
    }
  }
}

.header {
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 159%;
    text-align: center;
    color: #303134;
    &Mobile {
      display: none;
    }
  }
  &__button {
    width: 210px;
    max-height: 52px;
    padding: 14px 0;
    background: #ef6f22;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 156%;
    text-align: center;
    color: #ffffff;
    transition: 0.3s;
    &:hover {
      background-color: #f28c4e;
    }
    &:disabled {
      cursor: not-allowed;
      background: #f7b790;
    }
    &Reset {
      margin-right: 20px;
      width: 130px;
      max-height: 52px;
      padding: 14px 0;
      background: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 156%;
      color: #5796d2;
      text-align: center;
      border: 1.5px solid #5796d2;
      border-radius: 4px;
      transition: 0.3s;
      &:hover {
        color: #196faf;
        border: 1.5px solid #196faf;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.button__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button__calculate {
  position: relative;
}
.hide {
  display: none;
  position: relative;
}
.header__button:hover:disabled + .hide {
  display: block;
  text-align: center;
  position: absolute;
  top: -50px;
  left: -45px;
  width: 300px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
  padding: 6px 8px;
}
.spiner-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  img {
    width: 45px;
  }
}

.creditsAvailabilityContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.creditsAvailabilityText {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #c24040;
}
.creditsAvailabilityLink {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #196faf;
}

.contentHeaderBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 179%;
  text-align: center;
  white-space: nowrap;
  color: #5796d2;
  background-color: #fff;
  padding: 8px 12px;
  border: 1.5px solid #5796d2;
  border-radius: 4px;
  transition: 0.3s;
  svg {
    path {
      fill: #5796d2;
    }
  }
  &:hover {
    color: #196faf;
    border: 1.5px solid #196faf;
  }
}

@media screen and (max-width: 1024px) {
  .toggle_btn {
    font-size: 12px;
    width: 150px;
  }
  .description-container {
    padding: 30px 40px;
  }
  .description__item {
    font-size: 14px;
    &-small {
      margin-right: 30px;
    }
  }
  .content {
    padding: 31px 40px 40px;
    &__addUnit-text {
      font-size: 14px;
    }
  }
  .header {
    &__title {
      font-size: 26px;
    }
    &__button {
      width: 200px;
      padding: 14px 0;
      font-size: 14px;
      max-height: 49px;
      &Reset {
        margin-right: 10px;
        width: 120px;
        max-height: 49px;
        font-size: 14px;
      }
    }
  }
  .content {
    &__addUnit-disabled-text {
      font-size: 14px;
    }
    &__addUnit-text {
      font-size: 14px;
    }
  }
  .header__button:hover:disabled + .hide {
    left: -50px;
  }
}
@media screen and (max-width: 768px) {
  .toggle-container {
    width: 100%;
    margin: 80px 0 41px;

    .contentHeaderBtn {
      display: none;
    }

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
  }

  .toggle__family {
    justify-content: center;
  }

  .history-container {
    display: block;
    width: 100%;
    margin: 20px 0;

    .contentHeaderBtn {
      display: flex;
      width: 100%;
    }
  };

  .description-container {
    width: 100%;
    margin: 0 0 60px;
    padding: 20px;
    flex-direction: column;
    text-align: start;
  }
  .description__item {
    &-small {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &-big {
      width: 100%;
    }
  }
  .content {
    width: 100%;
    margin: 0;
    padding: 24px 12px;
    flex-direction: column;
    &__header {
      justify-content: center;
      margin-bottom: 0;
      margin-top: 24px;
      order: 3;
    }
    &__addUnit {
      margin: 0 auto;
    }
    &__name {
      margin-bottom: 24px;
      &-input {
        height: 29px;
        font-size: 18px;
      }
      &-image {
        width: 20px;
        height: 20px;
      }
    }
  }
  .header {
    &__title {
      display: none;
      &Mobile {
        display: block;
        margin-top: 20px;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__button {
      font-size: 14px;
    }
  }
  .header__button:hover:disabled + .hide {
    font-size: 14px;
  }
}
@media screen and (max-width: 425px) {
  .header {
    &__button {
      padding: 0;
      width: 161px;
      height: 41px;
      &Reset {
        padding: 0;
        width: 110px;
        height: 41px;
      }
    }
  }
}

// Process modal
.closeModalIcon {
  align-self: flex-end;
  width: 20px;
  height: 20px;
  color: #04062c;
  cursor: pointer;
}
.processModalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
  margin-bottom: 20px;
}
.processModalDescription {
  margin: 10px 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 268px;
}
.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.closeProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  background: #ffffff;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
  }
}
.confirmProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0 13px;
  cursor: pointer;
  &:hover {
    background-color: #79abdb;
  }
}
@media screen and (max-width: 1020px) {
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .processModalDescription {
    font-size: 16px;
    line-height: 20px;
    max-width: 330px;
  }
  .closeProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .confirmProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}
@media screen and (max-width: 768px) {
  .closeProcessModalButton {
    width: 150px;
  }
  .confirmProcessModalButton {
    width: 150px;
  }

  .toggle_btn {
    flex: 1;
  }

  .toggle__family {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 425px) {
  .button__container {
    width: 100%;
  }
  .confirmModalButton {
    width: 170px;
    font-size: 12px;
    line-height: 18px;
  }
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalButtonContainer {
    margin: 5px;
  }
  .closeProcessModalButton {
    width: 130px;
  }
  .confirmProcessModalButton {
    width: 130px;
  }
}
