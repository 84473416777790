.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(100deg, #dfe9f3, #f4f5f6);
}
.title {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 24px;
}
.description {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}
@media screen and (max-width: 420px) {
  .title {
    font-size: 28px;
    line-height: 34px;
  }
  .description {
    font-size: 18px;
    line-height: 22px;
  }
}
