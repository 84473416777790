body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
}
* {
  font-family: 'Roboto';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
// normilize
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

// styles
.container {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
}

._container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}

.controls-container {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  .contentHeaderBtn {
    display: none;
  }
}

.printNearbyPdf {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 12px;
  background: #b3bcc9;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: 0.3s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: #c2c9d4;
  }
  &:disabled {
    background: #d2d5d8;
    cursor: not-allowed;
    border: 1px solid #d2d5d8;
    color: #ffffff;
  }
}

.downloadNearbyPdf {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 12px;
  background: #74b566;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: #90c485;
  }
  &:disabled {
    background: #b9dab2;
    cursor: not-allowed;
    border: 1px solid #d2d5d8;
  }
}

.downloadNearbyPdfText {
  color: #ffffff;
}

.result {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 116px 0 40px 0;
  }
  &__title {
    font-weight: 700;
    font-size: 34px;
    line-height: 134%;
    color: #303134;
  }
  &__items {
    display: flex;
    gap: 5px;
  }
  &__item {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    background-color: #b3bcc9;
    border-radius: 4px;
    &:hover {
      background-color: #c2c9d4;
    }
    &-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: #ffffff;
      margin-left: 10px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__aside {
    width: 20%;
  }
  &__table {
    width: 75%;
    display: flex;
    flex-direction: column;
  }
}

.aside {
  &__recalculate {
    width: 100%;
    padding: 14px 0;
    background: #ef6f22;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 156%;
    text-align: center;
    color: #ffffff;
    transition: 0.3s;
    &:hover {
      background-color: #f28c4e;
    }
  }
  &__content {
    padding: 30px;
    background-color: #fff;
    border: 1px solid #d2dbe8;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 83px;
    min-height: 518px;
  }
  &__item {
    margin-bottom: 50px;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
    &_total {
      font-weight: 500;
      font-size: 18px;
      line-height: 178%;
      text-align: center;
      color: #303134;
      margin-bottom: 10px;
      &-small {
        margin-bottom: 2px;
      }
      &-description {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #717780;
        margin-bottom: 8px;
      }
    }
    &_description {
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #717780;
    }
  }
}

.resultTable {
  &__content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 30px;
  }
  &__total {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-bottom: 83px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    max-height: 58px;
    &-gray {
      background-color: #f8fafd;
    }
    &-darkGray {
      background-color: #e9edf3;
    }
    &-label {
      font-weight: 700;
      font-size: 16px;
      line-height: 156%;
      color: #303134;
    }
    &-section {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #303134;
    }
  }
  &__order {
    padding: 16px 12px;
    width: 10%;
  }
  &__address {
    padding: 16px 12px;
    width: 53%;
  }
  &__bedrooms {
    padding: 16px 12px;
    width: 10%;
  }
  &__bathrooms {
    padding: 16px 12px;
    width: 10%;
  }
  &__rent {
    padding: 16px 12px;
    width: 17%;
  }
  &__header {
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #303134;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: #303134;
      margin-right: 25px;
    }
  }
  &Multi {
    &__order {
      padding: 16px 12px;
      width: 8%;
    }
    &__address {
      display: flex;
      justify-content: space-between;
      padding: 16px 12px;
      width: 20.5%;
    }
    &__bedrooms {
      padding: 16px 12px;
      width: 10%;
    }
    &__bathrooms {
      padding: 16px 12px;
      width: 10%;
    }
    &__rent {
      padding: 16px 12px;
      width: 15%;
    }
    &__count {
      padding: 16px 12px;
      width: 18%;
    }
    &__total {
      padding: 16px 12px;
      width: 13%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .result {
    &__title {
      font-size: 28px;
    }
  }
  .aside {
    &__recalculate {
      font-size: 14px;
    }
    &__content {
      padding: 20px;
    }
    &__item {
      &_total {
        font-size: 18px;
      }
      &_description {
        font-size: 14px;
      }
    }
  }
  .resultTable {
    &__order {
      width: 7%;
    }
    &__rent {
      width: 22%;
    }
    &__item {
      &-label {
        font-size: 14px;
      }
      &-section {
        font-size: 14px;
      }
    }
    &Multi {
      &__order {
        width: 5%;
      }
      &__address {
        width: 26%;
      }
      &__bedrooms {
        width: 9%;
      }
      &__bathrooms {
        width: 9%;
      }
      &__rent {
        width: 22%;
      }
      &__count {
        width: 9%;
      }
      &__total {
        width: 13%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .controls-container {
    .contentHeaderBtn {
      display: flex;
      flex-grow: 1;
      width: 100%;
      margin: 0;
    }
  }

  .result {
    &__header {
      justify-content: center;
      flex-direction: column;
      padding: 90px 0 21px 0;
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #2d3035;
      text-align: center;
      margin-bottom: 21px;
    }
    &__item {
      display: flex;
      flex: 1;
      white-space: nowrap;
      padding: 8px 12px;
      font-weight: 600;
      img {
        width: 24px;
        height: 24px;
      }
    }
    &__items {
      width: 100%;
      .contentHeaderBtn {
        display: none;
      }
    }
    &__content {
      flex-direction: column;
      justify-content: center;
    }
    &__aside {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &__table {
      width: 100%;
      flex-direction: column;
    }
  }
  .aside {
    &__recalculate {
      width: 100%;
      font-size: 12px;
    }
    &__content {
      width: 80%;
      min-height: 0;
      padding: 20px 12px;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
    &__item {
      margin-bottom: 0;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 70px;
      max-height: 110px;
      &:nth-child(-n + 2) {
        margin-bottom: 30px;
      }
      &:nth-child(odd) {
        padding-right: 20px;
      }
      &_total {
        font-size: 16px;
        &-description {
          margin-bottom: 0;
        }
      }
      &_description {
        font-size: 14px;
      }
    }
  }
  .resultTable {
    &__item {
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
      max-height: 58px;

      &-gray {
        background-color: #f8fafd;
      }
      &-darkGray {
        background-color: #e9edf3;
      }
      &-label {
        font-weight: 500;
        font-size: 12px;
      }
      &-section {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__order {
      display: flex;
      align-items: center;
      padding: 15px 8px;
      width: 10%;
    }
    &__address {
      padding: 15px 8px;
      display: flex;
      overflow-wrap: break-word;
      width: 60%;
    }
    &__bedrooms {
      padding: 15px 8px;
      width: 30%;
    }
    &__bathrooms {
      padding: 15px 8px;
      width: 30%;
    }
    &__rent {
      padding: 15px 8px;
      width: 30%;
    }
    &__header {
      font-size: 14px;
      span {
        font-weight: 500;
        font-size: 14px;
      }
    }
    &Multi {
      &__order {
        display: flex;
        align-items: center;
        padding: 15px 8px;
        width: 10%;
      }
      &__address {
        display: flex;
        align-items: center;
        padding: 15px 8px;
        width: 50%;
      }
      &__bedrooms {
        padding: 15px 8px;
        width: 40%;
      }
      &__bathrooms {
        padding: 15px 8px;
        width: 40%;
      }
      &__rent {
        padding: 15px 8px;
        width: 40%;
      }
      &__count {
        padding: 15px 8px;
        width: 40%;
      }
      &__total {
        padding: 15px 8px;
        width: 40%;
      }
    }
  }
}

.spiner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: auto;
  flex: 1;
  img {
    width: 45px;
  }
}
@media screen and (max-width: 481px) {
  .container {
    background-size: 480%;
    background-position: top -80px right -270px;
  }
  .aside {
    &__content {
      width: 100%;
    }
  }
  .result__items {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
