.resultTable :global(.ant-table-content > table) {
  width: 100%;
}
.resultTable :global(.ant-table-cell) {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  padding: 16px 12px;
}
.titleCell {
  margin-left: 10px;
}
.resultTable :global(.ant-table-column-title) {
  margin-right: 14px;
}
.resultTable :global(.ant-table-thead > tr) {
  background: #ffffff;
}
.resultTable :global(.ant-table-thead > tr > td) {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
}
.resultTable :global(.ant-table-thead > tr > .ant-table-column-has-sorters) {
  cursor: pointer;
}
.resultTable :global(.ant-table-tbody > tr > td) {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
}
.resultTable :global(table) {
  border-collapse: initial;
  border-spacing: 0px;
}
.resultTable :global(.ant-table-tbody tr) {
  background: white;
  border-radius: 8px !important;
}
.resultTable :global(.ant-table-tbody tr:nth-child(even)) {
  background: #f8fafd;
}
.resultTable :global(.ant-table-thead > tr > th) {
  background: #ffffff;
  border: none;
}
.resultTable :global(.ant-table-thead > tr > .ant-table-column-has-sorters) {
  background: #ffffff;
}
.resultTable :global(.ant-table-thead > tr > .ant-table-column-has-sorters):hover {
  background: #ffffff;
}
.resultTable :global(.ant-table-thead tr th:first-child div) {
  margin-left: 18px;
}
.resultTable :global(.ant-table-tbody tr td), :global(.ant-table-thead tr th) {
  background: inherit;
}
.resultTable :global(.ant-table-thead tr th)::before {
  display: none;
}
.resultTable :global(.ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover),
{
  background-color: inherit;
}
.resultTable :global(.ant-table-row .ant-table-cell) {
  border: none;
}
.resultTable :global(.ant-table-thead > tr > th > div > span) {
  z-index: 0;
}
.resultTable :global(.ant-table-column-sorters) {
  display: block !important;
}
.resultTable :global(.ant-table-column-sorter) {
  display: inline-block;
  max-height: 16px;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
  }
}

@media screen and (max-width: 1020px) {
  .resultTable :global(.ant-table-thead > tr > th) {
    font-size: 14px;
    line-height: 14px;
    padding: 10px 8px;
    margin: 0 6px;
  }
  .resultTable :global(.ant-table-tbody > tr > td) {
    font-size: 14px;
    line-height: 14px;
    padding: 10px 8px;
  }
}

@media screen and (max-width: 420px) {
  .resultTable :global(.ant-table-thead > tr > th) {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 6px;
    margin: 0 6px;
  }
  .resultTable :global(.ant-table-tbody > tr > td) {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 6px;
  }
}
