.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: dashed 1px #1b74b3;
  background-color: #fff;
  border-radius: 12px;
  min-width: 460px;
  min-height: 134px;
}
.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.cloud > svg {
  margin: 24px 0;
  width: 57px;
  height: 43px;
  color: #000000;
}
.title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #717780;
  font-family: 'Roboto';
  margin-bottom: 24px;
}
.browse {
  color: #196faf;
  cursor: pointer;
}
.errorMessage {
  color: #ef3921;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto';
  margin: 5px 0;
}
.infoHint {
  display: inline;
  position: relative;
  margin-left: 7px;
  vertical-align: middle;
}
.infoHintIcon {
  svg {
    fill: #b3bcc9;
    transition: all 0.2s;
    &:hover {
      fill: #5796d2;
    }
  }
  &:hover + .hide {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: absolute;
    top: -130px;
    left: -158px;
    width: 330px;
    background-color: #5796d2;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    padding: 6px 8px;
    ul {
      list-style-position: inside;
      li {
        list-style: none;
        color: #fff;
      }
      li:before {
        content: '•';
        vertical-align: middle;
        margin-right: 8px;
        margin-left: 8px;
        color: #fff;
      }
    }
  }
}
.hide {
  display: none;
  &:after {
    align-self: center;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #5796d2;
  }
}
@media screen and (max-width: 1020px) {
  .container {
    min-width: 360px;
  }
  .title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 420px) {
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .container {
    min-width: 150px;
    min-height: 0;
    padding: 0px 8px;
  }
  .title {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .errorMessage {
    font-size: 10px;
    line-height: 13px;
  }
  .cloud > svg {
    margin: 14px 0;
    width: 42px;
    height: 36px;
  }
}
