.creditsAvailabilityContainer {
  display: flex;
  align-items: center;
  margin: 16px 0 20px 0;
}
.erroDropzone {
  display: flex;
  align-items: center;
  margin: 20px 0 0px 0;
}
.creditsAvailabilitySvg > svg {
  color: #ef3921;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.creditsAvailabilityText {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #c24040;
}
.creditsAvailabilityLink {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #196faf;
}

@media screen and (max-width: 1020px) {
  .creditsAvailabilityContainer {
    padding: 6px 8px;
  }
  .creditsAvailabilitySvg > svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
  .creditsAvailabilityText {
    font-size: 12px;
    line-height: 15px;
  }
  .creditsAvailabilityLink {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 420px) {
  .creditsAvailabilityContainer {
    padding: 4px 6px;
  }
  .creditsAvailabilitySvg > svg {
    width: 9px;
    height: 9px;
    margin-right: 4px;
  }
  .creditsAvailabilityText {
    font-size: 10px;
    line-height: 13px;
  }
  .creditsAvailabilityLink {
    font-size: 10px;
    line-height: 13px;
  }
}
