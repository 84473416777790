.menu {
  display: flex;
  width: max-content;
  margin-top: 8px;
  background-color: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #303134;
  &Header {
    margin: 16px 0;
    padding: 0 16px;
  }
  &Title {
    font-weight: 500;
    padding: 8px;
    cursor: pointer;
  }
  div:first-child {
    border-right: 1px solid #d2dbe8;
  }
  ul {
    list-style-type: none;
    li {
      border-radius: 8px;
      padding: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    li:hover {
      background-color: #f2f7fb;
    }
  }
}
.navigationBar {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid #f3f6f9;
  z-index: 1500;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.container {
  max-width: 1200px;
  padding: 0px 30px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 107px;
  height: 26px;
}
.buttonContainer {
  display: flex;
}
.buttonContainer > * {
  margin-right: 50px;
}
.buttonContainer > *:last-child {
  margin-right: 0;
}
.buttonContainerItem {
  position: relative;
}
.completedReportsButton {
  padding: 15px 0;
  align-self: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #717780;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    color: #303134;
  }
}
.rc-menu-item {
  border-radius: 5px;
}
.rc-menu-item-active {
  background-color: #f2f7fb;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .buttonContainer > * {
    margin-right: 15px;
  }
  .buttonContainer > *:last-child {
    margin-right: 0;
  }
  .newReportButton {
    font-size: 14px;
    line-height: 19px;
    padding: 6px 50px;
    margin-left: 15px;
    &:hover {
      opacity: 0.9;
    }
  }
  .logo {
    width: 100px;
    height: 22px;
  }
  .completedReportsButton {
    font-size: 14px;
    line-height: 19px;
  }
}
@media screen and (max-width: 420px) {
  .buttonContainer > * {
    margin-right: 5px;
  }
  .buttonContainer > *:last-child {
    margin-right: 0;
  }
  .newReportButton {
    font-size: 8px;
    line-height: 14px;
    padding: 6px 20px;
    margin-left: 6px;
    &:hover {
      opacity: 0.9;
    }
  }
  .logo {
    width: 90px;
    height: 20px;
  }
  .completedReportsButton {
    font-size: 12px;
    line-height: 14px;
  }
}
.rc-dropdown {
  margin-top: -10px;
}
