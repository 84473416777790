.table__items {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2d3035;
  margin-bottom: 56px;
  &:last-child {
    margin-bottom: 0;
  }
  &__number {
    width: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 178%;
    color: #303134;
    margin-right: 20px;
    text-align: left;
  }
  &__description {
    position: relative;
    width: 21%;
    font-weight: 400;
    margin-right: 25px;
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
      &-bottom {
        position: absolute;
        top: 42px;
        left: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #717780;
      }
    }
    &-input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 14px 10px 14px;
    }
  }
  &__bed {
    position: relative;
    width: 17.5%;
    font-weight: 400;
    margin-right: 25px;
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &__bath {
    position: relative;
    width: 17.5%;
    font-weight: 400;
    margin-right: 100px;
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &__count {
    position: relative;
    width: 14%;
    font-weight: 400;
    margin-right: 20px;
    &-input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 14px 10px 14px;
    }
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &__address {
    position: relative;
    width: 80%;
    //width: 70%;
    font-weight: 400;
    margin-right: 12px;
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
    &-input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 50px 10px 14px;
    }
    &-image {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 11px;
      top: 8px;
    }
  }
  &__bedrooms {
    position: relative;
    width: 20%;
    //width: 24%;
    font-weight: 400;
    margin-right: 12px;
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &__currentRent {
    position: relative;
    width: 24%;
    font-weight: 400;
    margin-right: 12px;
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
    &-inputContainer {
      position: relative;
    }
    &-input {
      width: 100%;
      border: 1px solid #b3bcc9;
      border-radius: 4px;
      padding: 10px 14px 10px 24px;
    }
    &-placeholder {
      position: absolute;
      top: 6px;
      left: 11px;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #717780;
    }
  }
  &__bathrooms {
    position: relative;
    width: 20%;
    //width: 24%;
    font-weight: 400;
    margin-right: 20px;
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
  &__additional {
    cursor: pointer;
    padding: 0 5px;
  }
}

// Multi-family table

.address {
  &__input {
    position: relative;
    width: 100%;
    font-weight: 400;
    margin-bottom: 80px;
  }
  &__label {
    position: absolute;
    bottom: 50px;
    left: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303134;
  }
  &__field {
    position: relative;
    width: 100%;
    border: 1px solid #b3bcc9;
    border-radius: 4px;
    padding: 10px 50px 10px 14px;
  }
  &__image {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 11px;
    top: 8px;
  }
}

// Dropdown
.rc-menu-item {
  border-radius: 5px;
}

.rc-menu-item-active {
  background-color: #f2f7fb;
  cursor: pointer;
}

.rc-menu-item-disabled {
  cursor: default;
  opacity: 0.4;
}

// Modal popup
.popup-message {
  font-family: 'Roboto';
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 152%;
  color: #2d3035;
}

@media screen and (max-width: 1024px) {
  .item {
    font-size: 14px;
    &__number {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 768px) {
  .address {
    &__input {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  .item {
    display: grid;
    grid-template-columns: 21px repeat(2, minmax(0, 1fr)) 20px;
    grid-auto-rows: 80px;
    margin-bottom: 0;
    row-gap: 0;
    column-gap: 15px;
    font-size: 14px;
    &__description {
      width: 100%;
      grid-column: 2 / 5;
      &-label {
        bottom: 45px;
        &-bottom {
          font-size: 9px;
        }
      }
    }
    &__bed {
      width: 100%;
      grid-column: 1 / 3;
      max-width: 200px;
      &-label {
        bottom: 45px;
      }
    }
    &__bath {
      width: 100%;
      grid-column: 3 / 5;
      max-width: 200px;
      margin-right: 0;
      &-label {
        bottom: 45px;
      }
    }
    &__count {
      width: 100%;
      grid-column: 1 / 4;
      &-label {
        bottom: 45px;
      }
    }
    &__address {
      width: 100%;
      grid-column: 2 / 5;
      grid-row: 1 / 2;
      &-label {
        bottom: 45px;
      }
    }
    &__bedrooms {
      width: 100%;
      grid-column: 1 / 3;
      max-width: 200px;
      &-label {
        bottom: 45px;
      }
    }
    &__bathrooms {
      width: 100%;
      grid-column: 3 / 4;
      position: relative;
      max-width: 200px;
      &-label {
        bottom: 45px;
      }
    }&__currentRent {
      width: 100%;
      grid-column: 1 / 4;
      grid-row: 3 / 4;
      position: relative;
      &-label {
        bottom: 45px;
      }
    }
  }
  .popup-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }
}

/* Chrome, Safari, Edge */
.item__currentRent-input::-webkit-outer-spin-button,
.item__currentRent-input::-webkit-inner-spin-button,
.item__count-input::-webkit-outer-spin-button,
.item__count-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
.item__currentRent-input[type="number"],
.item__count-input[type="number"] {
  -moz-appearance: textfield;
}