.toolsMain {
  position: relative;
  width: 100%;
  height: 100%;
}
.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 85%,
      rgba(255, 255, 255, 1)
    ),
    url('../../assets/images/mainPage/toolsPage_background.png');
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;
}
@media screen and (min-width: 1139.98px) {
  .backgroundImage {
    background-size: 100%;
  }
}
@media screen and (max-width: 767.98px) {
  .backgroundImage {
    display: none;
  }
  .toolsHeaderSection {
    background-image: url('../../assets/images/mainPage/toolsPage_background.png');
  }
}

.toolsMain {
  position: relative;
  flex: 1;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-size: cover;
}
.container {
  font-family: 'Roboto';
  max-width: 1200px;
  padding: 0px 30px;
  margin: 0 auto;
}

.toolsHeaderSection {
  padding: 146px 0 90px 0;
  text-align: center;
}
.toolsHeaderTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #196faf;
  margin-bottom: 16px;
}
.toolsHeaderBadge {
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 500;
  color: white;
  background-color: #f5a16f;
  border-radius: 2px;
  vertical-align: 40%;
  padding: 1px 4px 1px 4px;
  margin-left: 15px;
}
.toolsHeaderSubTitle {
  font-weight: 700;
  font-size: 38px;
  line-height: 134%;
  color: #2d3035;
  margin-bottom: 30px;
}
.toolsHeaderDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: #2d3035;
}

/* Content */

.toolsContent {
  &HeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 60px;
    margin-bottom: 50px;
  }
  &Title {
    font-weight: 500;
    font-size: 24px;
    line-height: 141%;
    color: #b3bcc9;
    position: relative;
    transition: color 0.3s;
    cursor: pointer;
    padding-bottom: 2px;
    &:hover {
      text-decoration: none;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #5796d2;
      transition: all 0.3s;
    }
    &:hover {
      color: #2d3035;
    }
    &:hover::after,
    &Active::after {
      width: 100%;
      left: 0;
    }
    &Active {
      color: #2d3035;
    }
  }
}
.toolsContentSection {
  margin-bottom: 80px;
}
.toolsContentMain {
  background: #ffffff;
  border: 2px solid #f3f6f9;
  border-radius: 20px;
  margin: 0 50px;
  padding-top: 50px;
}
.toolsContainerRent {
  margin-bottom: 80px;
}
.toolsContentDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  margin-bottom: 40px;
}
.toolsContentRentItems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin: 0 -50px;
}
.toolsContentRentItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  background-color: #ffffff;
  padding: 30px 15px 40px;
  text-align: center;
}
.toolsContentRentItemHeader {
  position: relative;
  margin-bottom: 18px;
}
.toolsContentRentItemTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
}
.toolsContentRentItemStatus {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b3bcc9;
}
.toolsContentRentItemDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  margin-bottom: 40px;
}
.toolsContentRentItemLink {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #196faf;
  margin-bottom: 40px;
  text-decoration: none;
}
.toolsContentRentItemLink:hover {
  text-decoration: underline;
}
.toolsContentRentItemBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
}
.toolsContentRentItemBtn {
  display: flex;
  justify-content: center;
  align-self: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 3px;
  height: 41px;
  transition: background-color 0.3s;
}
.toolsContentLearnMore {
  padding: 9px 19px;
  color: #5796d2;
  border: 1.5px solid #5796d2;
  background-color: #ffffff;
}
.disabledLink {
  pointer-events: none;
  opacity: 0.6;
}
.toolsContentLearnMore:hover {
  background-color: #f6f7f8;
}
.toolsContentGetStarted {
  cursor: pointer;
  padding: 11px 19px;
  color: #ffffff;
  background: #5796d2;
}
.toolsContentGetStarted:hover {
  background-color: #196faf;
}
.toolsContentGetStartedBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  color: #196faf;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
  &Inactive {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    color: #5796d2;
    svg {
      path {
        fill: #5796d2;
      }
    }
  }
}

.toolsContainerMarketing {
  margin-bottom: -25px;
}

.faq {
  background-color: #f8fafd;
}

@media screen and (max-width: 1185.98px) {
  /* Content */
  .toolsContentRentItemHeader {
    margin-bottom: 20px;
  }
  .toolsContentRentItemTitle {
    font-size: 20px;
  }
  .toolsContentRentItemStatus {
    font-size: 12px;
  }
  .toolsContentRentItemDescription {
    font-size: 14px;
  }
  .toolsContentRentItemBtnContainer {
    column-gap: 10px;
  }
  .toolsContentRentItemBtn {
    font-size: 12px;
  }
  .toolsContentLearnMore {
    padding: 9px 10px;
  }
  .toolsContentGetStarted {
    padding: 11px 10px;
  }
}
@media screen and (max-width: 767.98px) {
  .toolsHeaderSection {
    background-repeat: no-repeat;
    background-size: 100% 260%;
    padding: 96px 0 86px 0;
    text-align: left;
  }
  .toolsHeaderTitle {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
  }
  .toolsHeaderSubTitle {
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 30px;
  }
  .toolsHeaderDescription {
    font-size: 14px;
    line-height: 164%;
  }
  /* Content */
  .toolsContentSection {
    margin-bottom: none;
    margin: 40px 0 60px 0;
  }
  .toolsContentHeaderContainer {
    column-gap: 20px;
  }
  .toolsContentMain {
    background: none;
    border: none;
    border-radius: none;
    margin: 0;
    padding-top: 0;
  }
  .toolsContainerRent {
    margin-bottom: 80px;
  }
  .toolsContentTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .toolsContentDescription {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 30px;
  }
  .toolsContentRentItems {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    margin: 0;
  }
  .toolsContentRentItemHeader {
    margin-bottom: 10px;
  }
  .toolsContentRentItemTitle {
    font-size: 17px;
    line-height: 141%;
  }
  .toolsContentRentItemStatus {
    font-size: 14px;
    line-height: 16px;
  }
  .toolsContentRentItemDescription {
    font-size: 14px;
    line-height: 164%;
  }
  .toolsContentRentItemLink {
    font-size: 14px;
    line-height: 164%;
    margin-bottom: 30px;
  }
  .toolsContentRentItemBtnContainer {
    column-gap: 20px;
  }
  .toolsContentRentItemBtn {
    font-size: 16px;
    line-height: 19px;
  }
  .toolsContentLearnMore {
    padding: 9px 10px;
  }
  .toolsContentGetStarted {
    padding: 11px 10px;
  }
  .toolsContainerMarketing {
    margin-bottom: 0;
  }
}

.footer {
  border-top: 2px solid #f3f6f9;
}
.toolsFooter {
  &List {
    padding: 30px 0;
    list-style: none;
    display: flex;
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #717780;
      &:visited {
        color: #717780;
      }
      &:hover {
        color: #196faf;
      }
    }
  }
  &Item {
    margin-right: 50px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
.copyRight {
  padding: 40px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #717780;
}

.infoHint {
  display: inline;
  position: relative;
}

.infoHintIcon {
  &:hover + .hide {
    display: block;
    text-align: center;
    position: absolute;
    top: -62px;
    left: 50%;
    transform: translate(-50%);
    width: max-content;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #2d3035;
    padding: 6px 8px;
  }
}
.hide {
  display: none;
  &:hover {
    display: block;
    text-align: center;
    position: absolute;
    top: -62px;
    left: 50%;
    transform: translate(-50%);
    width: max-content;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #2d3035;
    padding: 6px 8px;
  }
  &:after {
    align-self: center;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #ffffff;
  }
}

@media screen and (max-width: 1024px) {
  .aboutTools {
    &Content {
      padding: 106px 65px 60px 0;
    }
    &Title {
      font-size: 22px;
      line-height: 26px;
    }
    &Subtitle {
      font-size: 30px;
    }
    &Description {
      font-size: 14px;
      padding-right: 45px;
    }
    &Image {
      max-width: 525px;
      max-height: 431px;
    }
  }
  .rentTools {
    &Container {
      padding: 80px 0px 70px 0;
    }
  }
  .rent {
    &Item {
      &:first-child {
        margin-bottom: 106px;
      }
    }
    &Content {
      margin-right: 20px;
    }
    &Title {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 30px;
    }
    &Description {
      font-size: 14px;
      line-height: 25px;
      color: #2d3035;
      margin-bottom: 30px;
    }
    &Button {
      padding: 12px 55px;
      font-size: 14px;
    }
  }
  .grpOrder {
    margin: 0 0 0 20px;
  }
  .toolsFooter {
    &List {
      a {
        font-size: 15px;
      }
    }
    &Item {
      margin-right: 40px;
    }
  }
  .copyRight {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .grpOrder {
    margin: 0px;
  }
  .aboutTools {
    flex-direction: column;
    &Content {
      order: 2;
      padding: 30px 0px 50px 0;
    }
    &Title {
      font-size: 18px;
      line-height: 20px;
    }
    &Subtitle {
      font-size: 24px;
      line-height: 38px;
    }
    &Description {
      font-size: 14px;
      padding-right: 0px;
    }
    &Image {
      margin-right: -85px;
      max-width: 400px;
      max-height: 300px;
      &Container {
        align-self: flex-end;
      }
    }
  }
  .rentTools {
    &Container {
      padding: 50px 0px 60px 0;
    }
  }
  .rent {
    &Item {
      flex-direction: column;
      &:first-child {
        margin-bottom: 80px;
      }
    }
    &Content {
      text-align: center;
      margin-right: 0px;
      order: 2;
    }
    &Title {
      font-size: 24px;
      margin: 36px 0 30px 0;
    }
    &Description {
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 40px;
    }
    &Button {
      padding: 10px 45px;
      font-size: 14px;
    }
  }
  .toolsFooter {
    &List {
      flex-wrap: wrap;
      a {
        font-size: 13px;
        line-height: 25px;
      }
    }
    &Item {
      margin-right: 30px;
    }
  }
  .copyRight {
    font-size: 12px;
  }
}
@media screen and (max-width: 425px) {
  .aboutTools {
    &Title {
      font-size: 13px;
      line-height: 18px;
    }
    &Subtitle {
      font-size: 20px;
      line-height: 26px;
    }
    &Description {
      font-size: 14px;
      line-height: 23px;
    }
  }
  .rent {
    &Title {
      font-size: 20px;
      line-height: 28px;
      margin: 36px 0 25px 0;
    }
    &Description {
      font-size: 14px;
      line-height: 23px;
    }
    &Button {
      padding: 8px 45px;
      line-height: 25px;
    }
  }
  .toolsFooter {
    &List {
      a {
        line-height: 25px;
      }
    }
  }
  .copyRight {
    line-height: 14px;
  }
}
