@import '../../../assets/scss/variables';

.container {
  min-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.content {
  margin: 146px auto 65px;
  padding: 44px 50px 65px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
}
// Header
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 54px;
}
.titleText {
  font-weight: 500;
  font-size: 32px;
  line-height: 159%;

  color: #2d3035;
}
.buttonsContainer {
  display: flex;
  column-gap: 20px;
}
.viewMyReports {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  width: 210px;
  height: 52px;
  text-align: center;
  color: #717780;
  border: 2px solid #b3bcc9;
  border-radius: 4px;
  transition: all 0.3s;
  &:hover {
    color: #2d3035;
    border-color: #2d3035;
  }
}
.buildReportButtonLoaded {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  width: 210px;
  height: 52px;
  color: #ffffff;
  background-color: #717780;
  transition: background-color 0.3s;
  &:hover {
    background-color: #717780cb;
  }
}
.buildReportButton {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  width: 210px;
  height: 52px;
  color: #ffffff;
  background-color: #ef6f22;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f28c4e;
  }
  &:disabled {
    cursor: not-allowed;
    background: #f7b790;
  }
}
.buildReportButtonContainer {
  position: relative;
}
.hide {
  display: none;
}
.buildReportButton:hover:disabled + .hide {
  display: block;
  text-align: center;
  position: absolute;
  top: -60px;
  left: -50px;
  width: 300px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
  padding: 6px 8px;
}
.creditsAvailabilityContainer {
  display: flex;
  align-items: center;
  margin: 26px 0 0 0;
}
.creditsAvailabilitySvg > svg {
  color: #ef3921;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.creditsAvailabilityText {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #c24040;
}
.creditsAvailabilityLink {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #196faf;
}
// Process modal
.processModalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
  margin-bottom: 20px;
}
.processModalDescription {
  margin: 10px 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 268px;
}
.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.closeProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  background: #ffffff;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
  }
}
.confirmProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}
.confirmModalButton {
  margin-top: 30px;
  width: 210px;
  background: #196faf;
  border-radius: 4px;
  padding: 14px 0px 13px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  align-self: center;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}
.headerSelect {
  margin-top: 10px;
}

@media screen and (max-width: 1020px) {
  .content {
    margin: 126px auto 50px;
    padding: 1.5rem 1.3rem;
  }

  .buildReportButton {
    padding: 14px 75px;
  }
  .buildReportButton:hover:disabled + .hide {
    top: 0;
    left: -110px;
    width: 250px;
    font-size: 12px;
    line-height: 14px;
    padding: 6px;
  }
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .processModalDescription {
    font-size: 16px;
    line-height: 20px;
    max-width: 330px;
  }
  .closeProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
  .confirmProcessModalButton {
    width: 190px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 840px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 420px) {
  .content {
    margin: 126px auto 30px;
    width: 85%;
    padding: 1rem 0.8rem;
  }
  .buildReportButton {
    padding: 8px 70px;
  }
  .processModalTitle {
    font-size: 20px;
    line-height: 25px;
  }
  .modalButtonContainer {
    margin: 5px;
  }
  .closeProcessModalButton {
    width: 130px;
  }
  .confirmProcessModalButton {
    width: 130px;
  }
}
