.bagdeGreen {
  font-size: 16px;
  line-height: 156.25%;
  font-weight: 700;
  color: white;
  background-color: #74b566;
  border-radius: 2px;
  vertical-align: 80%;
  padding: 3px 6px;
  margin-left: 15px;
}

@media screen and (max-width: 768px) {
  .bagdeGreen {
    font-size: 10px;
    padding: 2px 4px;
    margin-left: 5px;
  }
}
