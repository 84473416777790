.container {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
}
.grayContainer {
  background: #f8fafd;
}
.contentHeader {
  margin: 126px 0 24px 0;
  display: flex;
  justify-content: space-between;
  column-gap: 25px;
  &Container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &Title {
    font-weight: 700;
    font-size: 32px;
    line-height: 175%;
    color: #2d3035;
  }
  &SubTitle {
    font-weight: 400;
    font-size: 18px;
    color: #2d3035;
  }
  &Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 179%;
    text-align: center;
    white-space: nowrap;
    color: #5796d2;
    padding: 8px 12px;
    border: 1.5px solid #5796d2;
    background-color: #fff;
    border-radius: 4px;
    transition: filter 0.35s ease-in-out;
    svg {
      path {
        fill: #5796d2;
      }
    }
    &:hover {
      filter: brightness(1.1);
    }
    &Container {
      display: flex;
      column-gap: 12px;
      align-self: flex-start;
      column-gap: 10px;
      margin-top: 8px;
    }
    &Feedback {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 179%;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      color: #ffffff;
      padding: 8px 12px;
      border: 1.5px solid #5796d2;
      background-color: #5796d2;
      border-radius: 4px;
      transition: filter 0.35s ease-in-out;
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}
.nearbyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  max-height: 49px;
  padding: 14px 0px;
  background: #ef6f22;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  text-align: center;
  color: #ffffff;
  transition: 0.3s;
  &:hover {
    background: #be4a01;
  }
  &:disabled {
    cursor: not-allowed;
    background: #e69b6d;
  }
  svg {
    margin-right: 8px;
  }
}
.nearbyButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.creditBalance {
  font-size: 16px;
  font-weight: 400;
  color: #2d3035;
  line-height: 25px;
  margin-right: 30px;
  &Container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &Buy {
    color: #5796d2;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    text-decoration: none;
    transition: color 0.4s;
    &:hover {
      color: #196faf;
    }
  }
}

.table__items {
  display: flex;
  flex-direction: column;
}

.radioGroup {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 16px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 28px;
  flex-wrap: wrap;
  column-gap: 80px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2d3035;
  margin-bottom: 50px;
  &__Label {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    margin-bottom: 10px;
    color: #2d3035;
  }
  &_radio {
    border-radius: 7px;
    border: 1px solid #b3bcc9;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    padding: 7px 10px 7px 7px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    input[type='radio'] {
      accent-color: #5796d2;
      cursor: pointer;
    }
    &_active {
      background: #5796d2;
      color: #ffffff;
    }
  }
  &__address {
    position: relative;
    width: 100%;
    font-weight: 400;
    margin-bottom: 12px;
  }
  &__bedrooms {
    position: relative;
    font-weight: 400;
    &-label {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
}

.nearbyTable {
  border-collapse: collapse;
  width: 100%;
  font-family: 'Roboto';
  margin-bottom: 30px;
  td,
  th {
    padding: 5px;
    font-size: 14px;
    text-align: left;
  }
  tr {
    td {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  tr:nth-child(even) {
    background-color: #f8fafd;
  }
  tr:hover {
    background-color: #edeff1;
  }
  th {
    white-space: nowrap;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    background-color: #f3f6f9;
    color: #2d3035;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    border: none;
  }
}
.resultTableLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        stroke: black;
      }
    }
  }
  &Address {
    max-width: 270px;
    div {
      justify-content: flex-start;
    }
  }
}
.resultTableSvg {
  width: 10px;
  height: 20px;
  cursor: pointer;
}
.creditsAvailabilityContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 26px 0;
}
.creditsAvailabilitySvg > svg {
  color: #ef3921;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.creditsAvailabilityText {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #c24040;
}
.creditsAvailabilityLink {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
  color: #196faf;
}

// Process modal
.processModalTitle {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2d3035;
  margin-bottom: 20px;
}
.processModalDescription {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 340px;
}
.processModalDescriptionText {
  margin: 10px 0 25px 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  align-self: center;
  text-align: center;
  color: #2d3035;
  max-width: 340px;
}
.modalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 38px 20px 20px 20px;
}
.closeProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #2d3035;
  background: #ffffff;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
  }
}
.confirmProcessModalButton {
  width: 210px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background: #196faf;
  border-radius: 4px;
  border: none;
  padding: 14px 0px 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
}
.processModalClose {
  align-self: flex-end;
}
.confirmModalButton {
  margin-top: 30px;
  width: 210px;
  background: #196faf;
  border-radius: 4px;
  padding: 14px 0px 13px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  align-self: center;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #79abdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #79abdb;
  }
}
.headerSelect {
  margin-top: 10px;
}

.resultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  margin-top: 40px;
  border-top: 1px solid #d2dbe8;
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
  }
}
.nearbyButtonDownload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  max-height: 49px;
  padding: 14px 0px;
  background: #5796d2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 156%;
  text-align: center;
  color: #ffffff;
  transition: 0.3s;
  &:hover {
    background: #79abdb;
  }
  svg {
    margin-right: 8px;
    width: 21px;
    height: 21px;
  }
}
.fullContent {
  padding: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #d2dbe8;
  border-radius: 15px;
  margin-bottom: 100px;
}

.resultDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 40px;
  row-gap: 10px;
  margin-top: 24px;
  margin-bottom: 30px;
  &Item {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    strong {
      font-weight: 500;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d2d5d8;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #d2d5d8;
    transition: all 0.3s;
    &Icon path {
      transition: all 0.3s;
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
    &:hover {
      color: #5796d2;
      border-color: #5796d2;
      .paginationItemIcon path {
        fill: #5796d2;
      }
    }
  }
  &Active {
    color: #5796d2;
    border-color: #5796d2;
    .paginationItemIcon path {
      fill: #5796d2;
    }
  }
}

@media screen and (max-width: 1075px) {
  .nearbyTable {
    margin-bottom: unset;
    &Container {
      width: 100%;
      overflow-x: scroll;
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 995px) {
  .content {
    &Header {
      align-items: center;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}
@media screen and (max-width: 768px) {
  .content {
    &Container {
      padding: 0 20px;
    }
    &Header {
      margin-top: 110px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 24px;
      text-align: center;
      &Container {
        row-gap: 14px;
      }
      &Title {
        position: relative;
        font-size: 24px;
        line-height: normal;
        span {
          position: absolute;
          top: -25px;
          right: 50%;
          transform: translate(50%);
        }
      }
      &SubTitle {
        font-size: 14px;
        line-height: normal;
      }
      &Btn {
        width: 100%;
        font-weight: 700;
        line-height: 25px;
        padding: 8px 12px;
        background-color: #ffffff;
        &Container {
          width: 100%;
          column-gap: 12px;
          align-self: unset;
          margin-top: unset;
        }
        &Feedback {
          width: 100%;
          font-weight: 700;
          line-height: 25px;
          padding: 8px 12px;
        }
      }
    }
  }
  .content {
    margin: 28px auto 40px;
    padding: 28px 16px;
  }
  .statusTitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .header {
    flex-direction: column;
    margin-bottom: 20px;
    &Container {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    &Buttons {
      width: 100%;
      align-self: center;
    }
    &Button,
    &ButtonView {
      width: 100%;
      margin-top: 20px;
    }
  }
  .fullContent {
    padding: 32px 16px;
    margin-bottom: 28px;
  }
  .item {
    flex-direction: column;
    align-items: flex-start;
    line-height: normal;
    margin-bottom: 28px;
    &__Label {
      font-size: 14px;
      line-height: normal;
    }
    &_radio {
      flex: 1;
      white-space: nowrap;
    }
    &__address {
      width: 100%;
      margin-bottom: unset;
    }
    &__bedrooms {
      width: 100%;
    }
  }
  .radioGroup {
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }
  .nearbyButton {
    width: 100%;
    padding: 14px 0px;
    border-radius: 4px;
    font-size: 14px;
    line-height: normal;
    &Container {
      flex-direction: column-reverse;
      row-gap: 28px;
      width: 100%;
    }
  }
  .creditBalance {
    font-size: 12px;
    font-weight: 500;
    color: #717780;
    line-height: normal;
    margin-right: unset;
    &Container {
      row-gap: 8px;
    }
    &Buy {
      color: #196faf;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      &:hover {
        color: #5796d2;
      }
    }
  }
  .nearbyTable {
    td,
    th {
      padding: 12px 6px;
      font-size: 12px;
    }
    tr {
      td {
        font-size: 12px;
        line-height: normal;
      }
    }
    th {
      font-weight: 700;
      white-space: nowrap;
      text-align: center;
      line-height: normal;
    }
  }
  .resultTableLabel {
    column-gap: 8px;
  }
  .resultsHeader {
    flex-direction: column;
    row-gap: 20px;
    padding-top: 40px;
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
  }
  .nearbyButtonDownload {
    width: 100%;
    line-height: normal;
  }
  .resultDescription {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    &Item {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      strong {
        font-weight: 500;
      }
    }
  }
  .processModal {
    &Title {
      font-size: 18px;
      line-height: normal;
      margin-bottom: 10px;
    }
    &Description {
      font-size: 16px;
      max-width: 340px;
    }
    &DescriptionText {
      margin: 10px 0;
      font-size: 16px;
      line-height: 25px;
    }
  }
  .modalButtonContainer {
    margin: 20px 0;
  }
}
