.list-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1000;
  max-width: 315px;
}
.list-container__left {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 12px 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2d3035;
  cursor: grab;
  border: 1px solid #f3f6f9;
  min-width: 274px;
}
.listTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #2d3035;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f3f6f9;
  svg {
    cursor: pointer;
    width: 20px;
  }
}
.app__navigation {
  display: flex;
}
.app__navigation > a {
  margin: 10px;
  text-decoration: none;
  font-size: 20px;
}
