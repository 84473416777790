.dropdownContainer {
  display: block;
}
.menuItem {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  color: #2D3035;
  font-size: 14px;
  svg {
    path {
      fill: #474747;
    }
  }
}
.downloadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 179%;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background-color: #5796d2;
  padding: 8px 12px;
  border-radius: 4px;
  transition: 0.3s;
  svg {
    path {
      fill: #fff;
    }
  }
}
@media screen and (max-width: 768px) {
  .dropdownContainer {
    display: none;
  }
}
