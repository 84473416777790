._printContainer {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: #ffffff;
  padding: 30px;
}
.header {
  font-family: 'Roboto';
  color: #2d3035;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.headerLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.headerLogoText {
  margin-top: 6px;
  padding-left: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #717780;
}
.header_text {
  width: 180px;
  border-bottom: 1px solid #ef6f22;
  text-align: right;
}
.header_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}
.header_subtitle {
  font-size: 12px;
  line-height: 25px;
  margin-bottom: 5px;
}
.aside {
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 17px 0px;
  text-align: center;
  background: #f8fafd;
  border-radius: 8.62903px;
  color: #717780;
  margin-bottom: 30px;
}
.aside_item {
  width: 104px;
}
.aside_value {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2d3035;
}
.aside_title {
  margin-top: 14px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #717780;
}
.aside_titleSmall {
  margin-top: 6px;
}
.aside_subtitle {
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
  color: #717780;
}
.main {
  margin-bottom: 32px;
}
.main_table {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
}
.table_lables {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #2d3035;
  padding: 11px 0;
}
.table_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #2d3035;
  padding: 11px 0;
}
.table_item-gray {
  background: #f8fafd;
}
.number {
  width: 70px;
  margin-left: 20px;
}
.address {
  width: 550px;
  margin-left: 14px;
}
.bed {
  width: 108px;
  margin-left: 14px;
}
.bath {
  width: 108px;
  margin-left: 14px;
}
.rent {
  width: 176px;
  margin-left: 14px;
}
.total__lables {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #2d3035;
  padding: 11px 0;
}
.total_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #2d3035;
  padding: 11px 0;
  background: #e9edf3;
}
.multi_number {
  width: 70px;
  margin-left: 20px;
}
.multi_address {
  width: 186px;
  margin-left: 14px;
}
.multi_bed {
  width: 88px;
  margin-left: 14px;
}
.multi_bath {
  width: 88px;
  margin-left: 14px;
}
.multi_rent {
  width: 160px;
  margin-left: 14px;
}
.multi_count {
  width: 188px;
  margin-left: 14px;
}
.multi_total_rent {
  width: 148px;
  margin-left: 14px;
}
.multi_title {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #2d3035;
}
.noData_message {
  font-size: 18px;
  line-height: 16px;
  color: #2d3035;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-top: 200px;
}
